import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IActivePlayers, initActivePlayersState } from "../@types";

export const activePlayersSlice = createSlice({
  name: "activePlayers",
  initialState: initActivePlayersState,
  reducers: {
    setActivePlayers(state, action: PayloadAction<IActivePlayers[]>) {
      state.players = action.payload;
    },
  },
});
