import { createSlice } from "@reduxjs/toolkit";

export const loaderSlice = createSlice({
  name: "loader",
  initialState: { loading: true },
  reducers: {
    setLoader(state, Action) {
      state.loading = Action?.payload;
    },
  },
});
