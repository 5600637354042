import { colors } from "src/utils/theme"

export const playersTableData: {
   nickname: string
   odds?: number
   betAmount: number
   winAmount?: number
   color: string
}[] = [
   { nickname: 'Velda', betAmount: 1081, winAmount:1816,  color: colors.tertiary[6],},
   { nickname: 'Amulfo', betAmount: 118, winAmount:454,  color: colors.tertiary[6],},
   { nickname: 'Velda', betAmount: 440, winAmount:524,  color: colors.tertiary[6],},
   { nickname: 'Nakia', betAmount: 1165, color: colors.primary[6] },
   { nickname: 'Jena', betAmount: 1982, winAmount:350, color: colors.primary[3] },
   { nickname: 'Carlos', betAmount: 1902,winAmount:313, color: colors.primary[3] },
]
