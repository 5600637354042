import { Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { getConfig } from "src/store/store";
import { colors } from "src/utils/theme";

const CurrentValue = () => {
  const theme = useTheme();
  const textColor = theme.palette.mode === "dark" ? "#fff" : colors.gray[6];
  const t = useTranslation();
  const config = useSelector(getConfig).config;

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} gap={1}>
        <Typography
          sx={{
            background: colors.gray[7],

            textAlign: "center",
            borderRadius: "50%",
            color: colors.gray[1],
            fontSize: "13px",
            px: "6px",
          }}
        >
          6
        </Typography>
        <Typography color={textColor}>{t("info.gb_current_value")}</Typography>
      </Stack>
      <Typography sx={{fontSize:14}}>
        {t("info.gb_current_value")}{" "}
        <span style={{ color: "lightgreen" }}>{config.currency.symbol}0.0</span>
      </Typography>

      <Typography variant={"body"} color={textColor}>
        {t("info.gb_current_value_p1")}
      </Typography>
    </>
  );
};

export default CurrentValue;
