import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { InfoModalButtons } from "./InfoModalButtons";
import { FC, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-multi-lang";
import { InformationModalProps } from "../types/propsType";
import { ArrowLeft, CloseIcon, InformationIcon } from "./SvgComponent";
import { colors } from "src/utils/theme";
import { Trading_Version } from "src/constants";

export const InfoMainPage: FC<
  InformationModalProps & { setOpen: Dispatch<SetStateAction<boolean>> }
> = ({ activePage, setActivePage, setOpen }) => {
  const theme = useTheme();
  const t = useTranslation();

  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
          height={"100%"}
          gap={2}
          pt={2}
        >
          <Stack direction={"row"} alignItems={"center"} gap={"4px"}>
            <InformationIcon />
            <Typography
              variant={"headline"}
              sx={{
                color: theme.palette.mode === "light" ? colors.gray[6] : "",
              }}
            >
              {t("info.info_title")}
            </Typography>
          </Stack>

          <Stack onClick={() => setOpen(false)}>
            <CloseIcon />
          </Stack>
        </Stack>
      </Stack>

      <Box marginTop={"26px"}>
        <Typography
          variant={"bodySmall"}
          color={
            theme.palette.mode === "light" ? colors.gray[8] : colors.gray[6]
          }
        >
          {t('info.info_p1')} 
        </Typography>
      </Box>

      <InfoModalButtons activePage={activePage} setActivePage={setActivePage} />
      <Stack
            sx={{
               '.MuiTypography-root': {
                  color:
                     theme.palette.mode === 'light'
                        ? colors.gray[6]
                        : colors.gray[8],
               },
            }}
         >
            <Typography variant={'subtitle2'}>
               Game Version: {Trading_Version.Game}
            </Typography>
            <Typography variant={'subtitle2'}>
               RNG Version: {Trading_Version.RNG}
            </Typography>
         </Stack>
    </>
  );
};
export const GameModesModalButtons: FC<InformationModalProps> = ({
  activePage,
  setActivePage,
}) => {
  const t = useTranslation();
  return (
    <Stack direction={"column"} gap={1}>
      {/* <MultiButton
        title={t('gm_classic')}
        target={'classic'}
        activePage={activePage}
        setActivePage={setActivePage}
      />
      <MultiButton
        title={t('gm_overunder')}
        target={'overUnder'}
        activePage={activePage}
        setActivePage={setActivePage}
      />
      <MultiButton
        title={t('gm_range')}
        target={'Range'}
        activePage={activePage}
        setActivePage={setActivePage}
      /> */}
    </Stack>
  );
};
export const GameModes: FC<
  InformationModalProps & { setOpen: Dispatch<SetStateAction<boolean>> }
> = ({ activePage, setActivePage, setOpen }) => {
  const theme = useTheme();
  const textColor = theme.palette.mode === "dark" ? "#fff" : colors.gray[6];
  const t = useTranslation();

  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"} alignItems={"center"} gap={1} pt={2}>
          <IconButton onClick={() => setActivePage("info")}>
            <ArrowLeft />
          </IconButton>
          <Typography
            variant={"headline"}
            color={theme.palette.mode === "light" ? colors.gray[5] : "#fff"}
          >
            {t("gm_title")}
          </Typography>
        </Stack>
      </Stack>

      <Box marginTop={"26px"}>
        <Typography variant={"bodySmall"} color={textColor}>
          {t("info_p1")}
        </Typography>
      </Box>

      <GameModesModalButtons
        activePage={activePage}
        setActivePage={setActivePage}
      />
    </>
  );
};
