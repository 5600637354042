import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { colors } from "src/utils/theme";

const OddBox: FC<any> = ({ winAmount, countId, date, ...rest }) => {
  const borderColor =
    Number(winAmount) > 1.8 ? colors.tertiary[5] : colors.gray[6];
  const color =
    winAmount === "In Play"
      ? "#fff !important"
      : Number(winAmount) < 0
      ? colors.gray[2]
      : colors.gray[10];
  return (
    <Stack
      sx={{
        bgcolor: borderColor,
        borderRadius: "10px",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "54px",
        minWidth: "56px",
        py: "4px",
        cursor: "pointer",
      }}
    >
      <Typography
        id={countId}
        variant={"caption"}
        component={"span"}
        sx={{
          color:
            countId === "unknownPoint" || countId === "unknownPointCrash"
              ? "#fff !important"
              : color,
          fontFamily: "Nunito Sans,sans-serif !important",
          fontWeight: "600 !important",
        }}
      >
        {/* {winAmount === "In Play" ? "MD5" : winAmount}
          {winAmount !== "In Play" && winAmount !== "MD5" && "x"}
        */}
        {Math.abs(winAmount)}
      </Typography>
    </Stack>
  );
};

export default OddBox;
