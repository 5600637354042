import { GRAPH_SPEED } from "src/data/constants";
import { ISpeedFix } from "../types"; 
import { boxWithText } from "./BoxWithText";

//-- speed fix when ticker.FPS falls below 70%
export const speedFix = ({ renderer, parent }: ISpeedFix) => {
  let inputFPS: HTMLElement | null = document.getElementById("fps");
  if (inputFPS !== null)
    inputFPS.innerHTML = `${renderer.ticker.FPS.toFixed(0)}`;

  if (renderer.ticker.FPS > GRAPH_SPEED * 0.7) return;
  const container = boxWithText({
    text: "",
    x: -1000,
    y: 0,
    fillColor: "transparent",
  });
  parent.addChild(container);
};
