// import { GamePlay } from "../classes/GamePlay";
// // import { initLang } from "../helpers/lang";

import { Events } from "src/@types";
import { globalVars } from "src/data/globals";
import { buyRequestHandler } from "src/handlers/buyRequestHandler";
import { sellRequestHandler } from "src/handlers/sellRequestHandler";
import { buyRequestHandlerDemo, sellRequestHandlerDemo } from "src/helper/demoHelper";

// //Post messages
// export let gamePlay = new GamePlay();

let isTabActive = true;

document.addEventListener("visibilitychange", () => {
  isTabActive = !document.hidden;

  if (isTabActive) {
    isTabActive = true;
  } else {
    isTabActive = false;
  }
});

export const eventListener = () => {
  window.addEventListener("message", (e) => {
    // Check the origin
    const origin = e.origin;
    //TODO: Allow only whitelisted origins

    // console.log("e?.data?.event::", e?.data?.event, e?.data?.data);
    switch (e?.data?.event as Events) {
      case Events.BUY_REQ:
        if (globalVars.isOffline) {
          buyRequestHandlerDemo(e?.data?.data?.betAmount);
        } else {
          buyRequestHandler(e?.data?.data?.betAmount);
        }
        break;
      case Events.SELL_REQ:
        if (globalVars.isOffline) {
          sellRequestHandlerDemo(e?.data?.data?.betAmount);
        } else {
          sellRequestHandler(e?.data?.data?.betAmount);
        }
        break;
      /****
      case "gameStart":
        gamePlay.start();
        break;
      case "gameCrash":
        gamePlay.crash(e.data.data?.crash);
        break;
      case "gameWait":
        gamePlay.wait();
        break;
      case "gameHistory":
        if(e.data.data?.history?.lastRoundsHistory) {
          handleHistory(e.data.data?.history?.lastRoundsHistory);
          handleHistorySelectorResponsive();

        }
        break;
      case "gamePlayers":
        gamePlay.players(e?.data?.data?.count);
        break;
      case "gameNewRound":
        gamePlay.newRound(e?.data?.data?.newRound);
        break;
      case "gameTotalBets":
        console.log("Total", e?.data?.data)
        gamePlay.totalBets(e?.data?.data?.totalBets);
        break;
      case "gameBetsClosed":
        // betPlaceStatusUpdator("Bets are closed");
        break;
      case "countDown":
        counterClass.updateCountDown(e.data.data);
        break;
      case "sync":
        if (getGameState() === "init") {
          updateGameStatus("start");
        }
        break;
      case "config":
     
        config(e?.data?.data);
        break;
        */
      default:
        // console.log("unknown", e.data);
        break;
    }
  });
};

export const eventSender = (event: any, data: any) => {
  // send message to the parent
  window.parent.postMessage({ event, data }, "*");
};
