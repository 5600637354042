import { Modal, ModalProps, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { getConfig } from "../../store/store";
import { StyledModalBox } from "./CommonComponents";
import shadows from "@mui/material/styles/shadows";
import { ModalCrashProps } from "src/pages/trading/components/Game/@types";

export const ModalComponent = ({
  radius = 16,
  width = 350,
  height = 220,
  variant = 1,
  closeable = true,
  ...props
}: ModalProps & ModalCrashProps) => {
  const theme = useTheme();
  const config = useSelector(getConfig).config;
  return (
    <Modal
      closeAfterTransition
      sx={{
        "*": {
          outline: "none !important",
          fontFamily:
            config.lang.lang === "fa"
              ? "Iranian Sans, sans-serif !important"
              : "",
          // overflow: "hidden !important"
        },
      }}
      open={props.open}
      onClose={props.onClose}
    >
      <StyledModalBox
        sx={{
          maxHeight: "65vh",
          zIndex: 9999,
          position: "absolute",
          top: ["50%", "50%", "50%"],
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: shadows[0],
          borderRadius: `${radius}px`,
          minHeight: height,
          width,
          overflowY: "scroll",
          right: "-17px",
          // p: 1,
          height,
          background:
            theme.palette.mode === "dark"
              ? "linear-gradient(180deg, #1D1929 0%, #2D2740 100%)"
              : "#fff",
        }}
      >
        {props.children}
      </StyledModalBox>
    </Modal>
  );
};
