import { TableRow, styled } from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { colors } from 'src/utils/theme'


export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: `#13101E!important`,
        color: colors.gray[6] || theme.palette.grey[300],
        borderBottom: 0,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: 'white',
        borderBottom: 0,
        fontWeight: 700,
    },
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor:
            theme.palette.mode === 'dark'
                ? 'rgba(74, 71, 84, 0.1)'
                : colors.gray[11],
        borderBottom: 0,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}))