import { colors } from "src/utils/theme";
import { FilledInput, InputAdornment, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { getConfig, getPlayer, getPlayerBuyResult } from "src/store/store";
import { SetBetMax, UpdateBetAmount } from "src/logic";

export const InputComponent = () => {
  const theme = useTheme();
  const config = useSelector(getConfig).config; 
  const betAmount: number | undefined = useSelector(getPlayer).player.betAmount;
  const playerBuyResult = useSelector(getPlayerBuyResult).buyResult;
  //-- color-change [2023-06-23] ".MuiFilledInput-input"

  return (
    <FilledInput
      className="nonSelect"
      readOnly={playerBuyResult.success}
      // disabled={playerBuyResult.success}
      inputProps={{
        dir: "ltr",
        inputMode: "decimal",
        sx: {
          textAlign: "unset",
        },
      }}
      // value={betAmount.toFixed(2)}
      value={betAmount}
      onChange={(
        e: React.KeyboardEvent<HTMLInputElement> & { target: HTMLInputElement }
      ) => UpdateBetAmount(e.target.value)}
      size={"small"}
      autoFocus
      autoComplete={"off"}
      disableUnderline
      id="betAmount"
      sx={{
        color: theme.palette.mode === "dark" ? "#fff" : colors.gray[4],
        backgroundColor: "transparent",
        "&:hover": {
          backgroundColor: "transparent",
        },

        height: "43px",
        py: 0,
        ".MuiFilledInput-input": {
          fontSize: "18px",
          fontWeight: "700 !important",
          paddingTop: "16px",
          fontFamily: "Nunito Sans,sans-serif !important",
          "&:disabled": {
            backgroundColor: "transparent",
          },
        },
      }}
      startAdornment={
        <InputAdornment
          style={{
            padding: 0,
            marginRight: 0,
            opacity: 1,
            display: "inherit",
          }}
          position="start"
          className="nonSelect"
        >
          <span
            style={{
              color:
                theme.palette.mode === "dark"
                  ? "rgba(255, 255, 255, 0.4)"
                  : colors.gray[4],
              fontSize: "18px",
              paddingBottom: "4px",
              paddingRight: "4px",
            }}
          > 
            {config.currency.name}
          </span>
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment
          className={"nonSelect"}
          position="end"
          onClick={() => {
            !playerBuyResult.success && SetBetMax();
          }}
        >
          <span
            style={{
              color: "#FB8111",
              fontSize: 10,
              letterSpacing: "0.02em",
              fontWeight: "700 !important",
              lineHeight: "112%",
              cursor: "pointer",
            }}
          >
            Max
          </span>
        </InputAdornment>
      }
    />
  );
};
