import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { IPlayerHistory } from "src/@types";
import { initFloat } from "src/helper";
import { getConfig } from "src/store/store";
import { DashedLine } from "../../../../DesignIcons";
import { boldTextStyles, textStyles } from "../@styles";

const HistoryCardBody = ({
  playerHistory,
  bodyStyles,
}: {
  playerHistory: IPlayerHistory;
  bodyStyles: any;
}) => {
  const t = useTranslation();
  const config = useSelector(getConfig).config;
  const isRtl = useSelector(getConfig).config.lang.rtl;
  //-- color-change [2023-06-23]
  // const bgcolor = theme.palette.mode === "dark" ?  colors.gray[3] : colors.gray[11];
  const bgcolor = "transparent";

  return (
    <Stack direction="column" sx={{ ...bodyStyles, bgcolor }}>
      <Stack direction="row" justifyContent="space-between" width={"100%"}>
        <Stack gap="6px">
          <Typography sx={textStyles}> {t("translate.buy_price")} </Typography>
          <Typography sx={boldTextStyles}>
            {config.currency.symbol}
            {initFloat(playerHistory.betAmount)}
          </Typography>
        </Stack>
        <Stack gap="6px">
          <Typography sx={textStyles}>{t("translate.sell_price")} </Typography>
          <Typography sx={boldTextStyles} textAlign={isRtl ? "left" : "right"}>
            {config.currency.symbol}
            {initFloat(Number(playerHistory.winAmount))}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"10px"}
      >
        <DashedLine />
        <img
          width="56px"
          height="44px"
          src="/assets/png/ImoonBrandCard.png"
          alt="Logo"
        />
        <DashedLine />
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack gap="6px">
          <Typography sx={textStyles}>
            {t("translate.profit_amount")}{" "}
          </Typography>
          <Typography
            sx={[
              boldTextStyles,
              {
                color:
                  playerHistory.status.toLowerCase() === "win" ? "#2fa006" : "",
              },
            ]}
          >
            {Number(playerHistory.profit) < 0
              ? "-"
              : initFloat(playerHistory.profit)}
          </Typography>
        </Stack>
        <Stack gap="6px">
          <Typography sx={textStyles}>
            {t("translate.profit_percentage")}{" "}
          </Typography>
          <Typography
            sx={[
              boldTextStyles,
              {
                color:
                  playerHistory.status.toLowerCase() === "win" ? "#2fa006" : "",
                textAlign: isRtl ? "left" : "right",
              },
            ]}
          >
            {Number(playerHistory.profit) < 0
              ? "-"
              : initFloat(playerHistory.profitPercentage) +
                "%"}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default React.memo(HistoryCardBody);
