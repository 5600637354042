import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { FaVolumeUp, FaVolumeMute } from "react-icons/fa";

import { StyledIconBox } from "src/@shared";
import {
  playSound,
  preLoadSounds,
  stopSound,
  unLoadSounds,
} from "src/utils/sounds";

import { colors } from "src/utils/theme";

export const MuteButton = () => {
  const [isMuted, setIsMuted] = useState<boolean>(true);
  const theme = useTheme();
  const borderColor =
    theme.palette.mode === "dark" ? colors.gray[4] : colors.gray[10];
  const iconColor =
    theme.palette.mode === "dark" ? colors.gray[10] : colors.gray[8];

  useEffect(() => {
    preLoadSounds();
    return () => {
      setIsMuted(true);
      unLoadSounds();
    };
  }, []);
 
  const setSound = () => {
    if (isMuted) {
      playSound("bg_music");
    } else {
      stopSound("bg_music");
    }
    setIsMuted(!isMuted);
  };
  return (
    <StyledIconBox sx={{ borderColor: borderColor }}>
      {/* <FaVolumeUp color={iconColor} size={16} /> */}

      {isMuted ? (
        <FaVolumeMute onClick={setSound} color={iconColor} size={16} />
      ) : (
        <FaVolumeUp onClick={setSound} color={iconColor} size={16} />
      )}
    </StyledIconBox>
  );
};
