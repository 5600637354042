import { createSlice } from '@reduxjs/toolkit';

import {  initialStateIState } from '../@types';

export const stateSlice = createSlice({
  name: 'state',
  initialState: initialStateIState,
  reducers: {
    setStateTrack(state, Action): void {
      state.state = Action.payload;
    },
  },
});
