import { Connections, Network } from "src/@types";
import {
  PLAYER_INITIAL_BALANCE,
  PLAYER_INITIAL_BET_AMOUNT,
} from "src/data/constants";

export interface IGame {
  minBetAmount: number;
  maxBetAmount?: number;
  gameRunning: boolean;
  gameStatus: string;
  betsAreClosed: boolean;
  ackId: number;
}

export interface IGameState {
  game: IGame;
}

export const initialStateGame: IGameState = {
  game: {
    minBetAmount: 1,
    maxBetAmount: 1000,
    gameRunning: true,
    gameStatus: "init",
    betsAreClosed: false,
    ackId: 0,
  },
};

export const initNetworkState: Network = {
  connection: Connections.CONNECTING,
  networkError: "",
  networkMessage: "",
};

export interface IState {
  extendableOpen?: boolean;
}

export interface IStateTrack {
  state: IState;
}

export const initialStateIState: IStateTrack = {
  state: {
    extendableOpen: false,
  },
};
 
export interface IPlayerState {
  nickname?: string;
  balance?: number;
  betAmount?: number;
  success?: boolean;
}

export interface IPlayer {
  player: IPlayerState;
}

export const initialStatePlayer: IPlayer = {
  player: {
    nickname: "",
    balance: PLAYER_INITIAL_BALANCE,
    betAmount: PLAYER_INITIAL_BET_AMOUNT,
    success: true,
  },
};

export enum Languages {
  EN = "en",
  FA = "fa",
}

export enum Currencies {
  USD = "usd",
  IRT = "irt",
}
export interface Config {
  lang: { lang: Languages; rtl: boolean };
  currency: { name: Currencies; symbol: string; decimal: boolean };
  theme: { mode: "light" | "dark" };
  game: {
    sound?: boolean;
    guide?: boolean;
    playersCount?: boolean;
    totalBets?: boolean;
    isMultiBet: boolean;
    nextRoundBet?: boolean;
    isDemo?: boolean;
    particles?: boolean;
    cancelBet?: boolean;
    maintenance?: boolean;
    tvMode?: boolean;
    maxOddsClassic?: number;
    minOddsOverUnder?: number;
    maxOddsOverUnder?: number;
    minOddsRange?: number;
    maxOddsRange?: number;
    gameInfoURL?: string;
    gamePlayURL?: string;
    gamePlay?: string;
  };
}

export const initialConfig: Config = {
  lang: { lang: Languages.EN, rtl: false },
  currency: { name: Currencies.USD, symbol: "$", decimal: true },
  theme: { mode: "dark" },
  game: {
    sound: true,
    guide: true,
    playersCount: true,
    totalBets: true,
    isMultiBet: true,
    nextRoundBet: true,
    isDemo: false,
    particles: true,
    cancelBet: true,
    maintenance: false,
    tvMode: false,
    maxOddsClassic: 999.99,
    minOddsOverUnder: 1.02,
    maxOddsOverUnder: 24,
    minOddsRange: 1.01,
    maxOddsRange: 24,
  },
};

//////////////////////////////

export interface IActivePlayers {
  betId: string;
  nickname: string;
  betAmount: number;
  winAmount: number;
  status: string;
  playerHistory?: [];
}


export interface IActivePlayersState {
  players: IActivePlayers[];
}

export const initActivePlayersState: IActivePlayersState = {
  players: [],
};

export interface IPlayerBuyResultState {
  nickname: string;
  success: boolean;
  price: number;
  betAmount: number;
  buyAmount?: number;
}

export interface IPlayerSellResultState {
  nickname: string;
  success: boolean;
  price: number;
  buyAmount?: number;
  betAmount: number;
  winAmount?: number;
}

export interface IChatState {
  nickname: string;
  message: string;
}
export const initialChat: Array<IChatState> = [{ nickname: "", message: "" }];
