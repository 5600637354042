import { configureStore } from "@reduxjs/toolkit";
import { gameSlice } from "./reducers/gameSlice";
import { playerSlice } from "./reducers/playerSlice";
import { playerHistorySlice } from "./reducers/playerHistorySlice";
import { stateSlice } from "./reducers/stateSlice";
import { configSlice } from "./reducers/configSlice";
import { modalSlice } from "./reducers/modalSlice";
import { networkSlice } from "./reducers/networkSlice";
import { loaderSlice } from "./reducers/loaderSlice";
import { toastSlice } from './reducers/toastSlice'

import { activePlayersSlice } from "./reducers/activePlayersSlice";  
import { playerBuyResultSlice } from "./reducers/playerBuyResultSlice";
import { playerSellResultSlice } from "./reducers/playerSellResultSlice"; 
import { chatSlice } from "./reducers/chatSlice";

// Export the actions
export const { setGame } = gameSlice.actions;
export const { setPlayer } = playerSlice.actions;
export const { setPlayerHistory } = playerHistorySlice.actions;
export const { setStateTrack } = stateSlice.actions;
export const { setConfig } = configSlice.actions;
export const { setModal } = modalSlice.actions;
export const { setNetwork } = networkSlice.actions;
export const { setLoader } = loaderSlice.actions;
export const { setToast } = toastSlice.actions

export const { setActivePlayers } = activePlayersSlice.actions;  
export const { setPlayerBuyResult } = playerBuyResultSlice.actions;
export const { setPlayerSellResult } = playerSellResultSlice.actions; 
export const { setChat } = chatSlice.actions;

const store = configureStore({
  reducer: {
    game: gameSlice.reducer,
    player: playerSlice.reducer,
    playerHistory: playerHistorySlice.reducer,
    stateTrack: stateSlice.reducer,
    config: configSlice.reducer,
    modal: modalSlice.reducer,
    network: networkSlice.reducer,
    loader: loaderSlice.reducer,
    toast: toastSlice.reducer,
    
    activePlayers: activePlayersSlice.reducer,  
    playerBuyResult: playerBuyResultSlice.reducer,
    playerSellResult: playerSellResultSlice.reducer, 
    chat: chatSlice.reducer,
  },
});

type RootState = ReturnType<typeof store.getState>;

export const getGame = (state: RootState) => state.game;
export const getPlayer = (state: RootState) => state.player;
export const getPlayerHistory = (state: RootState) => state.playerHistory;
export const getState = (state: RootState) => state.stateTrack;
export const getConfig = (state: RootState) => state.config;
export const getModal = (state: RootState) => state.modal;
export const getNetwork = (state: RootState) => state.network;
export const getLoader = (state: RootState) => state.loader;
export const getToast = (state: RootState) => state.toast

export const getActivePlayers = (state: RootState) => state.activePlayers;  
export const getPlayerBuyResult = (state: RootState) => state.playerBuyResult;
export const getPlayerSellResult = (state: RootState) => state.playerSellResult; 
export const getChat = (state: RootState) => state.chat;

export default store;
