import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FaUsers } from "react-icons/fa";
import { useTranslation } from "react-multi-lang";
import { colors } from "src/utils/theme";

export const ActivePlayerCount = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const t = useTranslation();

  return (
    <Stack direction={"row"} gap={1.1} alignItems={"center"}>
      <FaUsers color={colors.gray[10]} size={isMobile ? 12 : 20} />
      <Typography
        component={"span"}
        variant={isMobile ? "bodySmall" : "bodyLarge"}
        sx={{
          color: theme.palette.mode === "dark" ? "white" : colors.gray[7],
          // fontSize: [12, 16, 16],
          fontSize: [10, 14, 14],
        }}
      >
        {t("translate.players")}:
        <span style={{ fontFamily: "Nunito Sans,sans-serif !important " }}>
          {" "}
          151
        </span>
      </Typography>
    </Stack>
  );
};

const ActivePlayers = () => {
  const theme = useTheme();
  const textColor = theme.palette.mode === "dark" ? "#fff" : colors.gray[6];
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const t = useTranslation();

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} gap={1}>
        <Typography
          sx={{
            background: colors.gray[7],
            // height: 17,
            // width: 17,
            textAlign: "center",
            borderRadius: "50%",
            color: colors.gray[1],
            fontSize: "13px",
            px: "6px",
          }}
        >
          5
        </Typography>
        <Typography color={textColor}>
          {t("info.gb_active_players")} 
        </Typography>
      </Stack>

      <ActivePlayerCount />
      <Typography variant={"body"} color={textColor}>
        {t("info.gb_active_players_p1")}
      </Typography>
    </>
  );
};

export default ActivePlayers;
