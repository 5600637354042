import { colors, theme } from "src/utils/theme";
//-- color-change [2023-06-23]
export const headerStyles = {
  // background: "rgba(29, 25, 41, 0.8)",
  bgcolor: theme.palette.mode === "dark" ? "rgba(29, 25, 41, 0.8)" : colors.gray[11],
  px: "16px",
  py: "6px",
  borderRadius: "8px 8px 0px 0px",
  borderWidth: "1px 1px 0px 1px",
  borderStyle: "solid",
  borderColor: "#3C3553",
  maxHeight: "28px",
};

export const textStyles = {
  fontSize: "14px",
  color: colors.gray[6],
  fontWeight: 400,
};

export const boldTextStyles = {
  ...textStyles,
  fontWeight: "700",
};

export const bodyStyles = {
  // background: colors.gray[3],
  bgcolor: theme.palette.mode === "dark" ? colors.gray[3] : colors.gray[11],
  // height: '120px',
  // maxWidth: '358px',
  px: "16px",
  pt: "12px",
  pb: "10px",
  borderRadius: "0px 0px 8px 8px",
  border: "1px solid #3C3553",
};
