import {
  Button,
  FormControl,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import { useState, KeyboardEvent } from "react";
import { ModalComponent } from "./ModalComponent";
import { BootstrapInput } from "src/@shared";
// import { nicknameResultHandler } from "src/handlers/nicknameResultHandler";
import { globalVars } from "src/data/globals";
import { nicknameRequestHandler } from "src/handlers/nicknameRequestHandler";
import { useTranslation } from "react-multi-lang";
import store from "src/store/store";
import { nicknameResultHandler } from "src/handlers/nicknameResultHandler";

export const NicknameModal = () => {
  const t = useTranslation();
  const isRtl = store.getState().config.config.lang.rtl;
  const [nickname, setNickname] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState('')
  const sendData = () => {
    if (nickname.length < 1) {
      setErrorMessage('Min characters 1')
   } else if (nickname.length > 128) {
      setErrorMessage('Max characters 128')
  //  } else {
  //     webSocketSender({ nickname: nickname }, 'setNickname')
   }

    if (globalVars.isOffline) {
      //-- for demo purposes only 2023-06-21
      nicknameResultHandler({ nickname });
    } else {
      nicknameRequestHandler(nickname);
    }
    globalVars.player.nickname = nickname;
  };
  const handleNickname = (
    e: KeyboardEvent<HTMLInputElement> & { target: HTMLInputElement }
  ) => {
    setNickname(e.target.value);
  };
  return (
    <ModalComponent
      open={true}
      height={"169px"}
      width={"370px"}
      radius={8}
      sx={{ overflowY: "hidden" }}
    >  
      <Stack p={2} direction={"column"} sx={{ overflowY: "hidden" }}>
        <FormControl fullWidth variant="standard">
          <InputLabel
            sx={{
              color: "#837C9C",
              fontSize: "12px",
              fontWeight: 300,

              textAlign: isRtl ? "right" : "left",
              width: "100vw",
            }}
            shrink
            htmlFor="bootstrap-input"
          >
            {t("translate.enter_name")}
          </InputLabel>
          <BootstrapInput
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
              e.key === "Enter" ? sendData() : null
            }
            onChange={handleNickname}
            value={nickname || ""}
            fullWidth
            id="bootstrap-input"
            inputProps={{
              maxLength: 100,
              minLength: 1,
              style: { textTransform: "lowercase" },
              dir: isRtl ? "rtl" : "ltr",
            }}
          />
          <Typography
            m={0.2}
            variant={"caption"}
            sx={{
              fontFamily: "Nunito Sans,sans-serif !important",
              fontSize: "12px",
            }}
            id="errorMessage"
          >
            {errorMessage}
          </Typography>
        </FormControl>

        <Button
          sx={{ mt: 2, border: "none" }}
          variant={"contained"}
          fullWidth
          onClick={sendData}
          // type="submit"
        >
          {t("translate.submit")}
        </Button>
      </Stack>
    </ModalComponent>
  );
};
 