import { PlayerBalance } from "./components/PlayerBalance";
import DisplayName from "./components/DisplayName";
import { TimeComponent } from "./components/Time";
import { HeaderBox } from "./@styles"; 

const HeaderComponent = () => {
  return (
    <HeaderBox>
      <PlayerBalance />
      <DisplayName />
      <TimeComponent />
    </HeaderBox>
  );
};

export default HeaderComponent;
