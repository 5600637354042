import { Stack, Typography } from '@mui/material'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { ChatIcon, HistoryIcon, PlayersIcon  } from '../../../../DesignIcons'
import { colors } from 'src/utils/theme'
import { capitalizeFirstLetter } from 'src/helper';




export enum TabsTypes {
    PLAYERS = "players",
    CHAT = "chat",
    HISTORY = "history",
}


interface TabsProps {
    activeTab: TabsTypes;
    setActiveTab: Dispatch<SetStateAction<TabsTypes>>;
}

const Tabs: FC<TabsProps> = ({ activeTab, setActiveTab }) => {

    const switchTab = (tab: TabsTypes) => {
        setActiveTab(tab)

    }

    return (

        <Stack px={4} pt={"8px"} direction={"row"} justifyContent={"space-between"}>

            {Object.keys(TabsTypes).map((tab, index) => (
                <Stack key={index} onClick={() => switchTab(tab.toLowerCase() as TabsTypes)} direction={"row"} gap={"4px"} alignItems={"center"}>
                    {tab.toLowerCase() === TabsTypes.PLAYERS && (
                        <PlayersIcon color={activeTab === TabsTypes.PLAYERS ? "#FB8111" : colors.gray[7]} />
                    )}
                    {tab.toLowerCase() === TabsTypes.CHAT && (
                        <ChatIcon color={activeTab === TabsTypes.CHAT ? "#FB8111" : colors.gray[7]} />
                    )}
                    {tab.toLowerCase() === TabsTypes.HISTORY && (
                        <HistoryIcon color={activeTab === TabsTypes.HISTORY ? "#FB8111" : colors.gray[7]} />
                    )}

                    {activeTab === tab.toLowerCase() && (
                        <Typography
                            sx={{
                                fontSize: "16px",
                                fontWeight: "700",
                                color: "#FB8111"

                            }}
                        > {capitalizeFirstLetter(tab.toLowerCase())}</Typography>
                    )}

                </Stack>
            ))}
        </Stack>
    )
}

export default Tabs