import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-multi-lang";
import { colors } from "src/utils/theme";
import { InformationModalProps } from "../../types/propsType";
import { ArrowLeft } from "../SvgComponent";
import ActivePlayers from "./ActivePlayers";
import BuySellHistory from "./BuySellHistory";
import BuySellShares from "./BuySellShares";
import CurrentValue from "./CurrentValue";
import GameChart from "./GameChart";
import StatusBadge from "./StatusBadge";
import ToggleAudio from "./ToggleAudio";

export const GameBoardComponent: FC<InformationModalProps> = ({
  activePage,
  setActivePage,
}) => {
  const theme = useTheme();
  const t = useTranslation();
  const textColor = theme.palette.mode === "dark" ? "#fff" : colors.gray[6];


  return (
    <Stack gap={1} pb={2}>
      <Stack
        direction={"row"}
        gap={1}
        alignItems={"center"}
        mb={1}
        sx={{
          position: "sticky",
          top: 0,
          bgcolor: theme.palette.mode === "dark" ? colors.gray[2] : "#fff",
          pt: 2,
          pb: 1,
          zIndex: 1,
        }}
      >
        <IconButton onClick={() => setActivePage("info")}>
          <ArrowLeft />
        </IconButton>
        <Typography
          variant={"headline"}
          color={theme.palette.mode === "light" ? colors.gray[5] : "#fff"}
        >
          {t("info.gb_title")}
        </Typography>
      </Stack>

      <Stack my={2}>
        <Typography variant={"body"} color={textColor}>
          {t("info.gb_intro")}
        </Typography>
      </Stack>

      <GameChart />

      <Stack gap={2}>
        <BuySellShares />
        <BuySellHistory />
        <StatusBadge />
        <ToggleAudio />
        <ActivePlayers />
        <CurrentValue />
      </Stack>
    </Stack>
  );
};
