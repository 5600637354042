import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-multi-lang";
import { gamePanelsTheme, initFloat } from "src/helper";
import store from "src/store/store";
import { colors } from "src/utils/theme";
import { InformationModalProps } from "../../types/propsType";
import { ArrowLeft } from "../SvgComponent";

const PlaceButtonsList = () => {
  const theme = useTheme();
  const textColor = theme.palette.mode === "dark" ? "#fff" : colors.gray[6];
  const t = useTranslation();

  const playerActionsList = [
    `${t("info.pa_place_bet_li1")}`,
    `${t("info.pa_place_bet_li2")}`,
    // `${t('info.pa_place_bet_li3')}`,
    // `${t('info.pa_place_bet_li4')}`,
  ];

  return (
    <Box mt={2}>
      <ul style={{  padding: "0 25px" , paddingTop: "0", margin: 0 }}>
        {playerActionsList.map((li) => (
          <li style={{ color: textColor }} key={li}>
            <Typography variant={"body"} color={textColor}>
              {li}
            </Typography>
          </li>
        ))}
      </ul>
    </Box>
  );
};

const PlaceBetButtons = () => {
  const theme = useTheme();
  const game = store.getState().game.game;
  const textColor = theme.palette.mode === "dark" ? "#fff" : colors.gray[6];
  const t = useTranslation();
  const config = store.getState().config.config;
  return (
    <Stack gap={2}>
      <Typography
        variant="subHeadline"
        color={theme.palette.mode === "dark" ? colors.gray[11] : colors.gray[6]}
      >
        {t("info.pa_place_bet")}
      </Typography>
      <Typography color={textColor} variant={"body"}>
        {t("info.pa_place_bet_intro")}
      </Typography>
      <PlaceButtonsList />
      <Stack gap={2}>
        <Button variant="contained" sx={{ border: "none" }}>
          {t("info.pa_place_bet_li1")}
        </Button>

        <Button
          variant="contained"
          sx={{
            bgcolor: "#0AAD36",
            boxShadow: "inset 0px -2.71765px 0px #235A11",
            border: "none",
            "&:hover": {
              backgroundColor: colors.tertiary[4],
            },
          }}
        >
          {t("info.pa_place_bet_li2")} ({config.currency.name}
          {initFloat(game.maxBetAmount || 10)})
        </Button>
      </Stack>
    </Stack>
  );
};
/*
const BetAmountInput: FC<{
   borderPosition?: { top: number | string; right: number | string }
}> = (props) => {
   const { borderPosition } = props
   const theme = useTheme()
   const isRtl = useSelector(getConfig).config.lang.rtl
   const game = store.getState().game.game
   const initLocalBets = [
      {
         mode: Modes.CLASSIC,
         betAmount: game.maxBetAmount || 10,
         odds: (2.0).toFixed(2),
         oddsRange: 0,
         playing: false,
      },
      {
         mode: Modes.OVERUNDER,
         betAmount: game.maxBetAmount || 10,
         odds: (2.0).toFixed(2),
         oddsRange: 0,
         playing: false,
      },
      {
         mode: Modes.RANGE,
         betAmount: game.maxBetAmount || 10,
         odds: (2.0).toFixed(2),
         oddsRange: 0,
         playing: false,
      },
   ]
   const [bet, setBet] = useState<BetStateProp[]>(initLocalBets)
   const styledIconsBoxSx = {
      width: '30px',
      height: '32px',
      justifyContent: 'center',
      borderColor: theme.palette.mode === 'dark' ? null : colors.gray[10],
   }
   const iconsBoxTypographySx = {
      fontSize: '12px',
      color: colors.primary[4],
      fontFamily: 'Nunito Sans,sans-serif !important ',
      fontWeight: '700 !important',
   }
   return (
      <Box my={2} sx={{ position: 'relative', pointerEvents: 'none' }}>
         {borderPosition && (
            <Box
               sx={{
                  position: 'absolute',
                  top: borderPosition.top,
                  right: borderPosition.right,
                  width: 52,
                  height: 52,
                  border: `4px solid ${colors.secondary[7]}`,
                  borderRadius: 50,
                  zIndex: 1,
               }}
            ></Box>
         )}
         <Stack
            dir={isRtl ? 'rtl' : 'ltr'}
            direction={'row'}
            sx={{
               bgcolor:
                  theme.palette.mode === 'dark'
                     ? colors.gray[3]
                     : colors.gray[11],
               alignItems: 'center',
               p: 1,
               borderRadius: '8px',
               justifyContent: 'space-between',
               borderColor:
                  theme.palette.mode === 'dark' ? '#474060' : '#DCDAE0',
               borderWidth: '1px',
               borderStyle: 'solid',
               opacity: 1,
            }}
            spacing={3.5}
         >
            <Box flex={1}>
               <BetAmountComponent
                  isDisabled={true}
                  bet={bet}
                  setBet={setBet}
               />
            </Box>
            <Stack
               direction={'row'}
               sx={{
                  alignItems: 'center',
                  gap: 1,
                  margin: isRtl ? '0 28px 0 0!important' : '0 0 28px 0',
               }}
            >
               <StyledIconsBox sx={styledIconsBoxSx}>
                  <BsShieldCheck color={'#D1CDE2'} size={16} />
               </StyledIconsBox>
               <StyledIconsBox sx={styledIconsBoxSx}>
                  <Typography sx={iconsBoxTypographySx}>1/2</Typography>
               </StyledIconsBox>
               <StyledIconsBox sx={styledIconsBoxSx}>
                  <Typography sx={iconsBoxTypographySx}>2x</Typography>
               </StyledIconsBox>
            </Stack>
         </Stack>
      </Box>
   )
}
*/
const BetMaxAmount = () => {
  const theme = useTheme();
  const textColor = theme.palette.mode === "dark" ? "#fff" : colors.gray[6];
  const t = useTranslation();

  return (
    <Stack gap={1}>
      <Typography
        variant="subHeadline"
        color={theme.palette.mode === "dark" ? colors.gray[11] : colors.gray[6]}
      >
        {t("info.pa_bet_max_amount")}
      </Typography>
      <Typography color={textColor} variant={"body"}>
        {t("info.pa_bet_max_amount_p1")}
      </Typography>
      {/* <img
            src={`${process.env.REACT_APP_CDNSERVER}/${theme.palette.mode}/playerAction_image1.jpg`}
            alt="Player Actions"
         /> */}
      {/************* <BetAmountInput borderPosition={{ top: 0, right: 143 }} /> */}
    </Stack>
  );
};

 
const Half = () => {
  const theme = useTheme();
  const textColor = theme.palette.mode === "dark" ? "#fff" : colors.gray[6];
  const t = useTranslation();

  return (
    <Stack gap={1}>
      <Typography
        variant="subHeadline"
        color={theme.palette.mode === "dark" ? colors.gray[11] : colors.gray[6]}
      >
        {t("info.pa_bet_half_amount")}
      </Typography>
      <Typography color={textColor} variant={"body"}>
        {t("info.pa_bet_half_amount_p1")}
      </Typography>
      {/* <img
            src={`${process.env.REACT_APP_CDNSERVER}/${theme.palette.mode}/playerAction_image3.jpg`}
            alt="Player Actions"
         /> */}
      {/************ <BetAmountInput borderPosition={{ top: 0, right: 36 }} /> */}
    </Stack>
  );
};
const Double = () => {
  const theme = useTheme();
  const textColor = theme.palette.mode === "dark" ? "#fff" : colors.gray[6];
  const t = useTranslation();

  return (
    <Stack gap={1}>
      <Typography
        variant="subHeadline"
        color={theme.palette.mode === "dark" ? colors.gray[11] : colors.gray[6]}
      >
        {t("info.pa_bet_double_amount")}
      </Typography>
      <Typography color={textColor} variant={"body"}>
        {t("info.pa_bet_double_amount_p1")}
      </Typography>
      {/* <img
            src={`${process.env.REACT_APP_CDNSERVER}/${theme.palette.mode}/playerAction_image4.jpg`}
            alt="Player Actions"
         /> */}
      {/****************  <BetAmountInput borderPosition={{ top: 0, right: -6 }} /> */}
    </Stack>
  );
};

const PlusMinus = () => {
  const theme = useTheme();
  const textColor = theme.palette.mode === "dark" ? "#fff" : colors.gray[6];
  const t = useTranslation();

  return (
    <Stack gap={1}>
      <Typography
        variant="subHeadline"
        color={theme.palette.mode === "dark" ? colors.gray[11] : colors.gray[6]}
      >
        {t("info.pa_bet_plus_minus")}
      </Typography>
      <Typography color={textColor} variant={"body"}>
        {t("info.pa_bet_plus_minus_p1")}
      </Typography>
      <img
        src={`${process.env.REACT_APP_CDNSERVER}/${theme.palette.mode}/playerAction_image5.jpg`}
        alt="Player Actions"
      />
    </Stack>
  );
};
export const PlayerActionsModalComponent: FC<InformationModalProps> = ({
  activePage,
  setActivePage,
}) => {
  const theme = useTheme();
  const t = useTranslation();
  return (
    <Stack gap={2}>
      <Stack
        direction={"row"}
        gap={1}
        alignItems={"center"}
        mb={1}
        sx={{
          position: "sticky",
          top: 0,
          bgcolor: theme.palette.mode === "dark" ? colors.gray[2] : "#fff",
          pt: 2,
          pb: 1,
          zIndex: 2,
        }}
      >
        <IconButton onClick={() => setActivePage("info")}>
          <ArrowLeft />
        </IconButton>
        <Typography
          variant={"headline"}
          color={theme.palette.mode === "light" ? colors.gray[5] : "#fff"}
        >
          {t("info.pa_title")}
        </Typography>
      </Stack>
      <PlaceBetButtons />
      <BetMaxAmount /> 
      <Half />
      <Double />
      {/* <PlusMinus /> */}
    </Stack>
  );
};
