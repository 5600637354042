import { Box } from "@mui/material";
import { ArrowDownIcon, ArrowUpIcon } from "../../../DesignIcons";
import { useState } from "react";
import Tabs, { TabsTypes } from "./components/Tabs";
import Players from "./components/TabContentComponents/Players";
import Chat from "../Chat";
import History from "../History";
import store, { getState, setStateTrack } from "src/store/store";
import { useSelector } from "react-redux";

export const ExtendableContent = () => {
  const [activeTab, setActiveTab] = useState<TabsTypes>(TabsTypes.HISTORY);
  const stateTracker = useSelector(getState).state;

  const handleClose = () => {
    store.dispatch(
      setStateTrack({
        ...stateTracker,
        extendableOpen: !stateTracker.extendableOpen,
      })
    );
  };

  return (
    <Box
      sx={{
        background:
          "linear-gradient(180deg, rgba(60, 53, 83, 0.4) 0%, rgba(60, 53, 83, 0) 100%)",
        backdropFilter: "blur(10px)",
        width: "100%",
        position: "absolute",
        left: "0",
        borderRadius: "16px 16px 0px 0px",
        zIndex: 500,
        px: 1,
        py: 0.5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        bottom: "170px",
        // top: "2vh",
        height: "100%",
        maxHeight: "450px",
      }}
    >
      <Box
        position={"relative"}
        height={"100%"}
        sx={{ maxHeight: "calc(100% - 60px)" }}
      >
        <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
        <Box
          sx={{
            height: "100%",
            marginTop: "20px",
          }}
        >
          {/* Tab Content */}
          {activeTab === TabsTypes.PLAYERS && <Players />}
          {activeTab === TabsTypes.CHAT && <Chat />}
          {activeTab === TabsTypes.HISTORY && <History />}
        </Box>

        {/* close button  */}
        {/* <Box
          sx={{
            position: "absolute",
            top: "-17px",
            right: "10px",
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </Box> */}
      </Box>
    </Box>
  );
};

export const ExtendableButton = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "59px",
        height: "22px",
        background: "rgba(60, 53, 83, 0.5)",
        borderRadius: "8px 8px 0px 0px",
        boxShadow: "inset 0px -2px 2px rgba(0, 0, 0, 0.25)",
      }}
    >
      {isOpen ? <ArrowDownIcon /> : <ArrowUpIcon />}
    </Box>
  );
};
