export let globalVars = {
  player: {
    nickname: "",
    betAmount: 0,
    buyPrice: 0,
    buyAmount: 0,
    winAmount: 0,
    price: 0,
    status: "",
  },
  playerHistory: [{}],
  backendDataPrice: 0,
  isOffline: false,
};
