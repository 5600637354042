import { Stack } from "@mui/material";
import { IPlayerHistory } from "src/@types";
import React from "react";
import { bodyStyles } from "../@styles";
import HistoryCardHeader from "./HistoryCardHeader";
import HistoryCardBody from "./HistoryCardBody";

function HistoryCard({ playerHistory }: { playerHistory: IPlayerHistory }) {
  
  return (
    <Stack>
      <HistoryCardHeader playerHistory={playerHistory} />
      <HistoryCardBody playerHistory={playerHistory} bodyStyles={bodyStyles} />
    </Stack>
  );
}

// export default React.memo(HistoryCard);
export default HistoryCard;
