import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-multi-lang";
import { IPlayerHistory } from "src/@types";
import { headerStyles, textStyles } from "../@styles";
import React from "react";
import { colors, theme } from "src/utils/theme";

export const HistoryCardHeader = ({
  playerHistory,
}: {
  playerHistory: IPlayerHistory;
}) => {
  const t = useTranslation();
  //-- color-change [2023-06-23]
  const bgcolor = theme.palette.mode === "dark" ?  colors.gray[3] : colors.gray[11];
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{...headerStyles, bgcolor}}
    >
      <Typography sx={textStyles}>
        {t(`translate.${playerHistory.status.toLowerCase()}`)}
      </Typography>

      <Stack direction="row">
        {Array.from({ length: 5 }, (_, index) => (
          <Box
            key={index}
            sx={{
              position: "relative",
              left: index > 0 ? `-${5 * index}px` : "0px",
            }}
          >
            {/* <OddBoxes boxSize="24px" animalId={1} won={index === 3} /> */}
          </Box>
        ))}
      </Stack>

      {/* <Typography sx={textStyles}>11:02:45</Typography> */}
      <Typography sx={textStyles}
      
      >{playerHistory.dateTime}</Typography>
 
    </Stack>
  );
};

export default HistoryCardHeader;
