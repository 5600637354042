import { w3cwebsocket } from "websocket";
import { backendDataPriceHandler } from "./backendDataPriceHandler";
import { Events } from "src/@types";
import { buyResultHandler } from "./buyResultHandler";
import { sellResultHandler } from "./sellResultHandler";
import { activePlayersResultHandler } from "./activePlayersResultHandler";
import { nicknameResultHandler } from "./nicknameResultHandler";
import { historyResultHandler } from "./historyResultHandler";

// export interface IEventData {
//   data: {
//     event: {};
//     data: {};
//   };
// }
export const eventReceiver = (eventData: any, WS: w3cwebsocket) => {
  const eventName = eventData?.data?.event;
  const messageData = eventData?.data?.data;

  switch (eventName) {
    // case Events.PLAYER_CONFIG:
    //   playerConfigHandler(messageData);
    //   break;
    case Events.HISTORY_RES:
      historyResultHandler(messageData);
      break;
    // case Events.BALANCE_RES:
    //   balanceResultHandler(messageData);
    //   break;
    case Events.BUY_RES:
      buyResultHandler(messageData);
      break;
    case Events.SELL_RES:
      sellResultHandler(messageData);
      break;
    case Events.NICKNAME_RES:
      nicknameResultHandler(messageData);
      break;
    case Events.BACKEND_DATA_PRICE:
      backendDataPriceHandler(messageData);
      break;
    case Events.ACTIVE_PLAYERS: 
      activePlayersResultHandler(messageData);
      break;
    default:
      break;
  }
};
