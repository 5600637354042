import { Box, Button, Stack } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { IPlayerHistory } from "src/@types";
import HistoryCardBody from "src/pages/trading/components/Game/components/BetBody/components/History/components/HistoryCardBody";
import store from "src/store/store";
import { ModalComponent } from "./ModalComponent";

export interface IHistoryModal {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  data: IPlayerHistory;
}
const HistoryModal = ({ open, setOpen, data }: IHistoryModal) => {
  const isRtl = store.getState().config.config.lang.rtl;
  return (
    <ModalComponent
      open={open}
      height={"250px"}
      radius={8}
      sx={{ overflowY: "hidden" }}
    >
      <Stack
        dir={isRtl ? "rtl" : "ltr"}
        p={2}
        direction={"column"}
        sx={{ overflowY: "hidden" }}
      >
        <Box p={0.5}>
          <HistoryCardBody playerHistory={data} bodyStyles={null} />
        </Box>
        <Button
          sx={{ mt: 2, border: "none" }}
          variant={"contained"}
          fullWidth
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
      </Stack>
    </ModalComponent>
  );
};

export default React.memo(HistoryModal);
