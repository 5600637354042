import React from 'react';
import { Box, Typography } from '@mui/material';
import { Rotate90DegreesCcw } from '@mui/icons-material';

const RotateScreen = () => (
    <Box
        sx={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999,
        }}
    >
        <Rotate90DegreesCcw color="primary" style={{ fontSize: 60 }} />
        <Typography variant="h4" component="div" color="white" marginTop={2}>
            Please rotate your device
        </Typography>
    </Box>
);
export default RotateScreen;
