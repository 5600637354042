import { getGame } from "src/store/store";
import  { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Game from "./components/Game";
import { Websocket } from "src/websocket/websocket";
import { configUpdater } from "src/utils/config"; 
import { theme } from "src/utils/theme";
// import { activePlayersRequestHandler } from "src/handlers/activePlayersRequestHandler";
// import { mainLoop } from 'src/mock'
import 'src/helper/demoHelper'
import { globalVars } from "src/data/globals";
import { runDemo } from "src/helper/demoHelper";
import { eventListener } from "src/listeners/messageListener";

const Trading = () => { 
  const isLoadedRef = useRef<boolean>(false);
  const alertUser = (e: BeforeUnloadEvent) => {
    if (gamesRunning) {
      e.preventDefault();
      alert("Are you sure you want to reload?");
      e.returnValue = "";
    }
  };
  const gamesRunning = useSelector(getGame).game.gameRunning;

  useEffect(() => {
    const websocket = new Websocket();
    if (!isLoadedRef.current) {
      isLoadedRef.current = true;
      /////// websocket.init();

      //-- for demo purposes only
      globalVars.isOffline = true;
      runDemo()
      

      const queryString = window.location.search
      const parameters = new URLSearchParams(queryString);
      /*
      if (!vCalled) {
        const version = parameters.get("v");
        if (version) {
  
          const styles = ["color: green"].join(";");
          const message = `GamePlay version is ${version}`;
          console.log("%c%s", styles, message);
          vCalled = true;
        }
      }
      */
  
      configUpdater(parameters, theme)
      eventListener();
 
    }
    return () => {
      websocket.close();
    };
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    // Mock websocket connection
    // mainLoop()
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
    // eslint-disable-next-line
  }, [gamesRunning]);

  // return <Game />;
  return (
    <>
      <Game />
      {/* <>{console.log("config::", config.lang)}</> */}
    </>
  );
};

export default Trading;
