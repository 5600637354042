import { Box, Stack } from "@mui/material";
import ChatInput from "./components/ChatInput";
import MessageCard from "./components/MessageCard";
import { colors } from "src/utils/theme";
import { useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { getChat } from "src/store/store";

function Chat() {
  const theme = useTheme();
  const messages = useSelector(getChat);
  //-- color-change [2023-06-23]
  const bgcolor = theme.palette.mode === "dark" ? colors.gray[3] : colors.gray[11];

  return (
    <Box
      sx={{
        bgcolor,
        borderRadius: "12px ",
        border: "1px solid #5B536F",
        height: "100%",
      }}
    >
      <Stack
        // height={['370px', '', '100%']}
        height={"100%"}
        direction={"column"}
        sx={{ p: 1.7, justifyContent: "space-between" }}
      >
        <Stack
          id={"messagedToScroll"}
          // maxHeight={"205px"}
          // maxHeight={"205px"}
          className={"nonSelect"}
          sx={{ overflowY: "auto" }}
          gap={1}
          pb={2}
        >
          {messages.map((item, index) => {
            if (!item.nickname) return null;
            return (
              <MessageCard
                nickname={item.nickname}
                message={item.message}
                key={"messages-" + index}
              />
            );
          })}
        </Stack>

        <ChatInput />
      </Stack>
    </Box>
  );
}

export default Chat;
