import { IPlayerHistory } from "src/@types";
import { globalVars } from "src/data/globals";
import store, { setPlayerHistory } from "src/store/store";

export const historyResultHandler = (history: IPlayerHistory[]) => {
  console.log("history::", history);
  // globalVars.playerHistory =  history; 
  const playerHistory: IPlayerHistory[]= store.getState().playerHistory.history;
  store.dispatch(setPlayerHistory([...history, ...playerHistory])) 
};
