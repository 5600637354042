import axios from "axios";
import { Events } from "src/@types";
import store from "src/store/store";
import { webSocketSender } from "src/websocket/websocket";

export const activePlayersRequestHandler = async (isInitial: boolean) => {
  try {
    const nickname = store.getState().player.player.nickname;
    webSocketSender({}, "activePlayers");
    const req: {} = {
      hubName: process.env.REACT_APP_TRADING_HUB_NAME,
      groupName: process.env.REACT_APP_PUB_SUB_GROUP,
      message: {
        event: Events.ACTIVE_PLAYERS,
        data: { isInitial, nickname },
      },
    };
    await axios.post("http://localhost:5000/trading/activePlayersRequest", req);
  } catch (error) {}
};
