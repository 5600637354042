import {
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { SnackbarProvider } from "notistack";
import { TbAntennaBars5 } from "react-icons/tb";
import { useSelector } from "react-redux";
import { BACKGROUND_IMAGE } from "src/data/constants";
import useIsMobileLandscape from "src/hooks/useLandscape";
import { NicknameModal } from "src/modals/nicknameModal";
import { getConfig, getPlayer, getState } from "src/store/store";
import { colors, theme } from "src/utils/theme";
import BetBody from "./components/BetBody";
import { ExtendableContent } from "./components/BetBody/components/ExtendableComponent";
import GameBody from "./components/GameBody";
import HeaderComponent from "./components/GameHeader";
import RotateScreen from "./components/RotateScreen";

const Game = () => {
  const stateTracker = useSelector(getState).state;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLandscape = useIsMobileLandscape();
  const nickname = useSelector(getPlayer).player.nickname;
  const isRtl = useSelector(getConfig).config.lang.rtl;
  //-- color-change [2023-06-23]
  const bgcolor = theme.palette.mode === "dark" ? "" : colors.gray[11];

  return (
    <>
      {isLandscape ? (
        <RotateScreen />
      ) : (
        <Stack
          dir={isRtl ? "rtl" : "ltr"}
          height={window.innerHeight} // convert to hook
          sx={{
            bgcolor,
            pt: 2,
            backgroundImage: `url("${BACKGROUND_IMAGE}") `,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          display={"flex"}
          flexDirection={"row-reverse"}
        >
          <Container
            sx={{
              position: "relative",
              overflow: "hidden",
              height: "100%",
              p: 0,
              // maxHeight: "760px"
            }}
          >
            <Stack
              direction={"column"}
              sx={{ maxWidth: "1300px" }}
              spacing={[0.1, 2, 2]}
              height={"100%"}
            >
              <Grid
                display={"flex"}
                flexDirection={"row-reverse"}
                justifyContent={"center"}
                container
                spacing={[0.5, 2, 2]}
                position={"relative"}
                height={["100%", null, null]}
              >
                <Grid
                  item
                  container
                  xs={12}
                  sm={11}
                  md={7.2}
                  lg={7.9}
                  display={"flex"}
                  direction={"column"}
                  // pr={[0.5, 1,2,0]}
                >
                  <Stack gap={"10px"} sx={{ height: "100%", width: "100%" }}>
                    <HeaderComponent />
                    <SnackbarProvider
                      maxSnack={1}
                      anchorOrigin={{
                        horizontal: isRtl ? "left" : "right",
                        vertical: "top",
                      }}
                    >
                      <GameBody />
                    </SnackbarProvider>
                  </Stack>
                </Grid>
                {/* Mobile */}
                <Grid
                  item
                  xs={12}
                  sm={11}
                  md={4.8}
                  lg={4.1}
                  mt={["auto", "", "0px"]}
                  //////   height={640}
                  // pr={[0.5, 1,2,0]}
                >
                  <BetBody />
                </Grid>
              </Grid>
            </Stack>

            {/* Extendable content */}
            {isMobile && stateTracker.extendableOpen && <ExtendableContent />}

            <Stack
              direction={"row"}
              gap={"4px"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                p: 1,
                width: "100%",
              }}
            >
              <Stack direction={"row"} alignItems={"center"}>
                <TbAntennaBars5 size={"16px"} color="#2fa006" />
                <Typography
                  color="#2fa006"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  Stable Connection
                </Typography>
              </Stack>

              {/* <Stack>
                                <Typography
                                    color='#2fa006'
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '400'
                                    }}
                                >Ping: 30ms</Typography>
                            </Stack> */}
            </Stack>

          </Container>
            {!nickname && <NicknameModal />}
        </Stack>
      )}
    </>
  );
};

export default Game;
