import { SmoothGraphics } from "@pixi/graphics-smooth";
import * as PIXI from "pixi.js";
import { IBoxWithText } from "src/@types";

export const boxWithText = ({
  text,
  x,
  y,
  color = "0xffffff",
  fillColor = "0x1F84EF",
  borderColor = fillColor,
}: IBoxWithText) => {
  const container: PIXI.Container = new PIXI.Container();
  const width = 100;
  const height = 60;
  const radius = 6;

  let elBox = new PIXI.Graphics();
  elBox
    .beginFill(fillColor)
    .lineStyle(2, borderColor)
    .drawRoundedRect(-(width / 2), 0, width, height, radius)
    .endFill();

  const textStyle: PIXI.TextStyle = new PIXI.TextStyle({
    fontFamily: "Arial",
    fontSize: 16,
    fill: color,
    align: "center",
  });
  const elText: PIXI.Text = new PIXI.Text(text, textStyle);
  elText.position.set(0, height / 2);
  elText.anchor.set(0.5);

  const line = new SmoothGraphics();
  line.lineStyle(1, 0x1f84ef).moveTo(0, 0).lineTo(0, 100);
  container.addChild(line);

  const marker = new SmoothGraphics();
  marker.beginFill(fillColor).drawCircle(0, 100, 10).endFill();
  container.addChild(marker);

  container.addChild(elBox);
  container.addChild(elText);

  container.x = x;
  container.y = y;
  return container;
};
