import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-multi-lang";
import store from "src/store/store";
import { colors } from "src/utils/theme";

const CurrentShareValue = () => {
  const t = useTranslation();
  const config = store.getState().config.config;
  return (
    <Stack
      direction={"row"}
      gap={0.8}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Typography
        component={"span"}
        variant={"caption"}
        sx={{
          color: colors.gray[6],
          fontSize: 10,
        }}
      > 
        {t("translate.current_value")}
      </Typography>
      <Typography
        component={"span"}
        variant={"caption"}
        sx={{
          color: colors.tertiary[6],
          fontSize: 10,
        }}
      >
        <span id="currentShareValue">
          {config.currency.symbol}0.0
          {/* currentShareValue amount comes from BetButton->generateY function*/}
        </span>
      </Typography>
    </Stack>
  );
};

export default CurrentShareValue;
