import { useSelector } from "react-redux";
import { initFloat } from "src/helper";
import { IPlayerState } from "src/store/@types";
import { getActivePlayers, getConfig, getPlayer } from "src/store/store";
import { IActivePlayers } from "../../@types";
import { PlayingTableBody, PlayingTableCell, PlayingTableRow } from "./@style";

const PlayersTableBody = () => {
  const player: IPlayerState = useSelector(getPlayer).player;
  const activePlayers: IActivePlayers[] = useSelector(getActivePlayers).players;
 
  const config = useSelector(getConfig).config; 

  return (
    <PlayingTableBody>
      {activePlayers?.map((row, index: number) => (
        <PlayingTableRow key={index * Math.random()} id={row?.betId}>
          {/* TODO: check nicknameBet class in css files */}
          <PlayingTableCell>
            <span
              className={"nicknameBet"}
              style={{
                color:
                  row.status?.toLowerCase() === "won"
                    ? "#0FC943"
                    : row.status?.toLowerCase() === "lost"
                    ? "#FF6768"
                    : row.nickname === player.nickname
                    ? "#72B6FF"
                    : "",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "62px",
                display: "flow-root",
                overflow: "hidden",
                fontWeight: "600 !important",
              }}
            >
              {row.nickname}
            </span>
          </PlayingTableCell>

          <PlayingTableCell>
            <span
              className={"betOdds"}
              id={`betOdds-${row.betId}`}
              style={{
                color:
                  row.status?.toLowerCase() === "won"
                    ? "#0FC943"
                    : row.status?.toLowerCase() === "lost"
                    ? "#FF6768"
                    : row.nickname === player.nickname
                    ? "#72B6FF"
                    : "",
              }}
            >
              {" "}
              {Number(row.betAmount) > 0
                ? config.currency.symbol + initFloat(row.betAmount)
                : "-"}
            </span>
          </PlayingTableCell>

          <PlayingTableCell>
            <span style={{ display: "none" }} id={`amount-${row.betId}`}>
              {initFloat(row.betAmount)}
            </span>
            <span
              className={"betAmount"}
              id={`betAmount-${row.betId}`}
              style={{
                color:
                  row.status?.toLowerCase() === "won"
                    ? "#0FC943"
                    : row.status?.toLowerCase() === "lost"
                    ? "#FF6768"
                    : row.nickname === player.nickname
                    ? "#72B6FF"
                    : "",
              }}
            >
              {!row.status || Number(row.winAmount) <= 0
                ? "-"
                : config.currency.symbol + initFloat(Number(row.winAmount))}
            </span>
          </PlayingTableCell>

          <PlayingTableCell className={`status-${row.status?.toLowerCase()}`}>
            <span
              className={"betWin"}
              id={`win-${row.betId}`}
              style={{
                color:
                  row.status?.toLowerCase() === "won"
                    ? "#0FC943"
                    : row.status?.toLowerCase() === "lost"
                    ? "#FF6768"
                    : row.nickname === player.nickname
                    ? "#72B6FF"
                    : "",
              }}
            >
              {" "}
              {row.status?.toLowerCase() === "won"}
              {row.winAmount && row.status?.toLowerCase() === "won"
                ? config.currency.symbol +
                  initFloat(
                    Math.abs(Number(row.winAmount) - Number(row.betAmount))
                  )
                : "-"}
            </span>
          </PlayingTableCell>
        </PlayingTableRow>
      ))}
    </PlayingTableBody>
  );
};

export default PlayersTableBody;
