// import { Currencies } from "@crash/common/src/types/types";
import { createSlice } from "@reduxjs/toolkit";
import { globalVars } from "src/data/globals";


export const playerBuyResultSlice = createSlice({
  name: "playerBuyResult",
  initialState: {
    buyResult: {
      nickname: globalVars.player.nickname,
      success: false,
      price: 0,
      amount: 0,
      betAmount: 0,
      // currency: { name: Currencies.USD, symbol: "$", decimal: true },
    },
  },
  reducers: {
    setPlayerBuyResult(state, action) {
      state.buyResult = action.payload;
    },
  },
});
 