import * as PIXI from "pixi.js";
import { GAME_BACKGROUND_SPEED } from "src/data/constants";
export class Background {
  app: PIXI.Application;
  imagePath: string;
  texture: PIXI.Texture;
  bg1: PIXI.Sprite;
  bg2: PIXI.Sprite;

  constructor(app: PIXI.Application, imagePath: string) {
    this.app = app;
    this.imagePath = imagePath;
    this.texture = PIXI.Texture.from(imagePath);
    this.bg1 = new PIXI.Sprite(this.texture);
    this.bg2 = new PIXI.Sprite(this.texture);
    this.init();
  }

  async init() {
    this.bg1.width = this.app.view.width;
    this.bg1.height = this.app.view.height;
    this.bg2.width = this.bg1.width;
    this.bg2.x = this.bg1.width;
    this.bg2.height = this.bg1.height;
    this.app.stage.addChild(this.bg1);
    this.app.stage.addChild(this.bg2);
  }
  update(delta: number) {
    this.bg1.x -= GAME_BACKGROUND_SPEED;
    this.bg2.x -= GAME_BACKGROUND_SPEED;
    if (this.bg1.x + this.bg1.width <= 0) {
      this.bg1.x = this.bg2.x + this.bg2.width;
    }
    if (this.bg2.x + this.bg2.width <= 0) {
      this.bg2.x = this.bg1.x + this.bg1.width;
    }
  }
  start() {
    this.app.ticker.add(this.update, this);
  }
  stop() {
    this.app.ticker.remove(this.update, this);
  }
}
