import { Stack } from "@mui/material";
import { MuteButton } from "./components/MuteButton";
import InfoButton from "./components/InfoButton";
import HistorySelector from "./components/HistorySelector";
import React from "react";

function Header() {
  return (
    <Stack
      dir={"ltr"}
      direction={"row"}
      justifyContent={"space-between"}
      position={"absolute"}
      width={"100%"}
      px={2}
      pt={1.6}
    >
      <Stack>
        <HistorySelector />
      </Stack>
      <Stack
        direction={"row"}
        gap={"5px"}
        alignItems={"center"}
        sx={{ marginRight: [2, 0] }}
        mb={1}
      >
        <Stack color="white">
          <span id="fps">fps</span>
        </Stack>
        {/* <HistoryButton /> */}
        <MuteButton />
        <InfoButton />
      </Stack>
    </Stack>
  );
}

export default React.memo(Header);
