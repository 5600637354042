import { Stack, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { colors, theme } from "src/utils/theme";
import { renderGraph } from "./helpers/RenderGraphHelper";
import { IPosition } from "./types";
import BuySellComponents from "./components/BuySellComponents";

const Chart: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const markerRef = useRef<{ position: IPosition }>();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  //-- color-change [2023-06-23]
  const bgcolor =
    // theme.palette.mode === "dark" ? colors.gray[2] : colors.gray[12];
    theme.palette.mode === "dark" ? "transparent" : colors.gray[12];

  useEffect(() => {
    renderGraph({ parentRef, canvasRef, markerRef, isMobile });
    return () => {
      canvasRef.current?.remove();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Stack
      sx={{
        height: ["60vh", "340px", "100%"],
        bgcolor,
      }}
      ref={parentRef}
    >
      <BuySellComponents markerRef={markerRef} />
      <canvas id={"gamePlay"} ref={canvasRef} style={{ borderRadius: "8px" }} />
    </Stack>
  );
};

export default React.memo(Chart);
