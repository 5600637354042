import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { colors, theme } from 'src/utils/theme'

function MessageCard(
    props: {
        nickname: string
        message: string
    }

) {
    return (
        <Stack
            // dir={player?.nickname === props.nickname ? 'ltr' : 'rtl'}
            direction={'row'}
            id={'playerMessageBox'}
            sx={{
                background:
                    theme.palette.mode === 'dark' ? colors.gray[5] : colors.gray[10],
                borderRadius: '4px',
                p: 1,
            }}
            gap={1}
        >
            <Box id={'playerNameBox'}>
                <Typography
                    variant={'caption'}
                    sx={{
                        color:
                            theme.palette.mode === 'dark'
                                ? colors.gray[11]
                                : colors.gray[8],
                    }}
                >
                    {props.nickname}
                </Typography>
            </Box>

            <Box id={'playerMessageBox'}>
                <Typography
                    variant={'caption'}
                    sx={{
                        color:
                            theme.palette.mode === 'dark'
                                ? colors.gray[8]
                                : colors.gray[6],
                        fontSize: 12,
                        overflowWrap: 'anywhere',
                    }}
                >
                    {props.message}
                </Typography>
            </Box>
        </Stack>
    )
}

export default MessageCard