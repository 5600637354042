
import { FC } from 'react';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { BoxProps } from '@mui/system';
import { colors } from 'src/utils/theme';

export const HeaderBox: FC<& BoxProps> = ({ ...props }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    //-- color-change [2023-06-23]
    const bgcolor= !isMobile ? (theme.palette.mode === "dark" ? colors.gray[2] : colors.gray[12]) : "transparent";
    return (
        <Box
            {...props}
            sx={{
                bgcolor,
                borderRadius: "8px",
                border: !isMobile ? `1px solid ${theme.palette.mode === "dark" ? colors.gray[3] : colors.gray[9]}` : "",
                height: ["38px", "48px", "48px"],
            }}>
            <Stack
                dir={'ltr'} 
                direction={'row'}
                alignItems={'center'}
                sx={{
                    px: 2,
                    alignItems: "center",
                    height: "100%",
                    pb: 0,
                    borderRadius: '8px',
                    justifyContent: 'space-between',
                }}
            >
                {props.children}
            </Stack>

        </Box >
    )
}