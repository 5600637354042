import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-multi-lang";
import store from "src/store/store";
import { PlacedToast } from "../ToastComponent/ToastComponent";
import { colors } from "src/utils/theme";

export const StatusBadgeToast = () => {
  const game = store.getState().game.game;
  return (
    <Box
      sx={{
        background: colors.tertiary[7],
        width: "200px",
        p: 0.5,
        borderRadius: "4px",
      }}
    >
      <PlacedToast amount={game.maxBetAmount || 10} isNextRound={false} />
    </Box>
  );
};

const StatusBadge = () => {
  const theme = useTheme();
  const textColor = theme.palette.mode === "dark" ? "#fff" : colors.gray[6];
  const t = useTranslation();

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} gap={1}>
        <Typography
          sx={{
            background: colors.gray[7],
            // height: 17,
            // width: 17,
            textAlign: "center",
            borderRadius: "50%",
            color: colors.gray[1],
            fontSize: "13px",
            px: "6px",
          }}
        >
          3
        </Typography>
        <Typography color={textColor}>{t("info.gb_status_badge")}</Typography>
      </Stack>
      <StatusBadgeToast />
      <Typography variant={"body"} color={textColor}>
        {t("info.gb_status_badge_p1")}
      </Typography>
    </>
  );
};

export default StatusBadge;
