import { Box, Modal, ModalProps, styled, useTheme } from '@mui/material' 
import { ModalCrashProps } from '../../@types'
import { gradients, shadows } from 'src/utils/theme'



export const StyledModalBox = styled(Box)(() => ({
    background: gradients['5'],
}))

export const ModalComponent = ({
    radius = 16,
    width = 350,
    height = 220,
    variant = 1,
    closeable = true,
    ...props
}: ModalProps & ModalCrashProps) => {
    const theme = useTheme()
    return (
        <Modal
            closeAfterTransition
            sx={{
                '*': {
                    outline: 'none !important',

                },
            }}
            open={props.open}
            onClose={props.onClose}
        >
            <StyledModalBox
                sx={{
                    maxHeight: '65vh',
                    zIndex: 9999,
                    position: 'absolute',
                    top: ['50%', '50%', '50%'],
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    boxShadow: shadows[0],
                    borderRadius: `${radius}px`,
                    minHeight: height,
                    width,
                    overflowY: 'scroll',
                    right: '-17px',
                    height,
                    background:
                        theme.palette.mode === 'dark'
                            ? 'linear-gradient(180deg, #1D1929 0%, #2D2740 100%)'
                            : '#fff',
                }}
            >
                {props.children}
            </StyledModalBox>
        </Modal>
    )
}


