import { Stack, Typography } from '@mui/material'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { ChatIcon, HistoryIcon, PlayersIcon } from '../../../../DesignIcons'
import { colors, theme } from 'src/utils/theme' 
import { useTranslation } from 'react-multi-lang';




export enum TabsTypes {
    PLAYERS = "players",
    CHAT = "chat",
    HISTORY = "history",
}


interface TabsProps {
    activeTab: TabsTypes;
    setActiveTab: Dispatch<SetStateAction<TabsTypes>>;
}

const Tabs: FC<TabsProps> = ({ activeTab, setActiveTab }) => {
    const t = useTranslation();
    //-- color-change [2023-06-23]
    const bgcolor = theme.palette.mode === "dark" ? colors.gray[3] : colors.gray[11]
  
    const switchTab = (tab: TabsTypes) => {
        setActiveTab(tab)

    }

    return (

        <Stack px={1} pt={"10px"} direction={"row"} justifyContent={"space-between"}>


            {Object.keys(TabsTypes).map((tab, index) => (
                <Stack 
                className="nonSelect"
                sx={{ cursor: "pointer", background: tab.toLowerCase() === activeTab ? bgcolor : "", px: 2, py: 1, borderRadius: "6px" }} key={index} onClick={() => switchTab(tab.toLowerCase() as TabsTypes)} direction={"row"} gap={"4px"} alignItems={"center"}>
                    {tab.toLowerCase() === TabsTypes.PLAYERS && (
                        <PlayersIcon color={colors.gray[7]} />
                    )}
                    {tab.toLowerCase() === TabsTypes.CHAT && (
                        <ChatIcon color={colors.gray[7]} />
                    )}
                    {tab.toLowerCase() === TabsTypes.HISTORY && (
                        <HistoryIcon color={colors.gray[7]} />
                    )}


                    <Typography
                       
                        sx={{
                            fontSize: "12px",
                            fontWeight: "700",
                            color: colors.gray[7]

                        }}
                    > 
                     {t(`translate.${tab.toLowerCase()}`)}  
                    </Typography>


                </Stack>
            ))}


        </Stack>
    )
}

export default Tabs