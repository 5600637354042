import { Stack, Typography, useTheme } from "@mui/material";
import { isMobile } from "pixi.js";
import { colors } from "src/utils/theme";

import { FaUsers } from "react-icons/fa";
import { useSelector } from "react-redux";
import { getActivePlayers } from "src/store/store";
import { useTranslation } from "react-multi-lang";

const ActiveUsers = () => {
  const theme = useTheme();
  const t = useTranslation();
  const activePlayersCount: number =
    useSelector(getActivePlayers).players?.length;
  return (
    <Stack direction={"row"} gap={1.1} alignItems={"center"}>
      <FaUsers color={colors.gray[10]} size={isMobile ? 12 : 20} />
      <Typography
        component={"span"}
        variant={isMobile ? "bodySmall" : "bodyLarge"}
        sx={{
          color: theme.palette.mode === "dark" ? "white" : colors.gray[7],
          fontSize: [12, 16, 16],
        }}
      > 
        {t("translate.players")} 
        <span style={{ fontFamily: "Nunito Sans,sans-serif !important " }}>
          {" "}
          {activePlayersCount}
        </span>
      </Typography>
    </Stack>
  );
};

export default ActiveUsers;
