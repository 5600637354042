import { createSlice } from '@reduxjs/toolkit';

import { initialStateGame } from '../@types';

export const gameSlice = createSlice({
  name: 'game',
  initialState: initialStateGame,
  reducers: {
    setGame(state, Action): void {
      state.game = Action.payload;
    },
  },
});
