import { Theme } from "@mui/material";
// import { Currencies, Languages } from "@types";
// import store, { setConfig, setLoader } from "store";
// import { getCurrencyData } from "helper";
import { setLanguage } from "react-multi-lang";
import { eventSender } from "../listeners/messageListener";
import { Currencies, Languages } from "src/@types";
import { getCurrencyData } from "src/helper";
import store, { setConfig, setLoader } from "src/store/store";
const supportedLanguages = [
  "en",
  "zhHans",
  "ja",
  "th",
  "vi",
  "id",
  "de",
  "es",
  "fr",
  "ru",
  "fi",
  "ro",
  "it",
  "da",
  "fil",
  "mt",
  "fa",
  "km",
  "kr",
  "nl",
  "my", 
  "mm", 
  "cn", 
];

export const configUpdater = (parameters: URLSearchParams, muiTheme: Theme) => {
  const theme = parameters.get("theme");
  const langP = parameters.get("lang");
  const currency = parameters.get("currency");

  const isDemo = parameters.get("isdemo");
  const isParticles = parameters.get("particles");

  new Promise((resolve, reject) => {
    const config = store.getState().config.config;

    store.dispatch(
      setConfig({
        ...config,
        theme: {
          ...config.theme,
          mode: theme === "light" ? "light" : "dark",
        },
      })
    );
    muiTheme.palette.mode = theme === "light" ? "light" : "dark";
    resolve(true);
  })
    .then(() => {
      const config = store.getState().config.config;
      let lang = "en";
      if (langP) {
        lang = supportedLanguages.includes(langP) ? langP : Languages.EN;
        setLanguage(lang);
      }

      new Promise((resolve, reject) => {
        store.dispatch(
          setConfig({
            ...config,
            lang: {
              ...config.lang,
              lang,
              rtl: [Languages.FA].includes(langP as Languages),
            },
          })
        );
        resolve(true);
      });
    })
    .then(() => {
      const config = store.getState().config.config;

      store.dispatch(
        setConfig({
          ...config,
          currency: {
            ...config.currency,
            name: getCurrencyData(currency as Currencies).name,
            symbol: getCurrencyData(currency as Currencies).symbol,
            decimal: getCurrencyData(currency as Currencies).decimal,
          },
        })
      );
    })
    .then(() => {
      const config = store.getState().config.config;

      store.dispatch(
        setConfig({
          ...config,
          game: {
            ...config.game,
            isDemo: isDemo === "true" ? true : false,
          },
        })
      );
    })
    .then(() => {
      const config = store.getState().config.config;
      if (isParticles === "true" || isParticles === "false") {
        store.dispatch(
          setConfig({
            ...config,
            game: {
              ...config.game,
              particles:
                isParticles === "true"
                  ? true
                  : isParticles === "false"
                  ? false
                  : config.game.particles,
            },
          })
        );
      }
    });
  store.dispatch(setLoader(false));
  //   console.log(store.getState().config.config);
};

export const langUpdate = (langP: string) => {
  const config = store.getState().config.config;
  let lang = "en";
  if (langP) {
    lang = supportedLanguages.includes(langP) ? langP : Languages.EN;
    setLanguage(lang);
  }
  new Promise((resolve, reject) => {
    store.dispatch(
      setConfig({
        ...config,
        lang: {
          ...config.lang,
          lang,
          rtl: [Languages.FA].includes(langP as Languages),
        },
      })
    );
    resolve(true);
    eventSender("gameLoaded", true);
  });
};
