import { t } from "react-multi-lang";
import store from "src/store/store";
import { colors } from "src/utils/theme";
import { IBuyMarker } from "../types";
import { boxWithText } from "./BoxWithText";

export const addBuyMarker = ({
  parent,
  markerRef,
  playerBuyResult,
}: IBuyMarker) => {
  if (!markerRef.current?.position) return;
  const { x, y } = markerRef.current?.position;
  const config = store.getState().config.config;
  const title = t("translate.buy");
  const container = boxWithText({
    text: `${title}\n${
      config.currency.symbol
    }${playerBuyResult.betAmount.toFixed(2)}`,
    x: parent.x * -1 + x,
    y: parent.y * -1 + y - 100,
    fillColor: colors.secondary[5],
  });
  parent.addChild(container);
};
