import { Stack, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-multi-lang";
import { colors } from "src/utils/theme";
import OddBox from "./OddBox";

const BuySellHistory = () => {
  const theme = useTheme();
  const textColor = theme.palette.mode === "dark" ? "#fff" : colors.gray[6];
  const t = useTranslation();

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} gap={1}>
        <Typography
          sx={{
            background: colors.gray[7],
            // height: 17,
            // width: 17,
            textAlign: "center",
            borderRadius: "50%",
            color: colors.gray[1],
            fontSize: "13px",
            px: "6px",
          }}
        >
          2
        </Typography>
        <Typography color={textColor}>{t("info.gb_point_history")}</Typography>
      </Stack>
      <Stack direction={"row"} gap={1}>
        <OddBox
          sx={{ cursor: "pointer" }}
          winAmount={-23.91}
          date={Date.now()}
        />
        <OddBox sx={{ cursor: "pointer" }} winAmount={7.41} date={Date.now()} />
      </Stack>

      <Typography variant={"body"} color={textColor}>
        {t("info.gb_point_history_p1")}
      </Typography>
    </>
  );
};

export default BuySellHistory;
