import { Stack, Typography  } from "@mui/material";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { initFloat } from "src/helper";
import { getConfig } from "src/store/store"; 
import { colors } from "src/utils/theme";


export const BuyToast = (props: { amount: number }) => {
    const config = useSelector(getConfig).config;
    const t = useTranslation();

    return (
        <Stack direction={config.lang.rtl ? "row-reverse" : "row"} alignItems={"center"} gap={1}>
            <Typography sx={{ fontSize: "12px", color: colors.secondary[1], fontWeight: 600 }}>{t("translate.you_have_bought")}</Typography>
            <Typography sx={{
                fontSize: "12px",
                color: "white",
                fontWeight: 600
            }}>{config.currency.symbol}{initFloat(props.amount)}    </Typography>
        </Stack>
    )
}

export const SellToast = (props: { amount: number }) => {
    const config = useSelector(getConfig).config;
    const t = useTranslation();

    return (
        <Stack direction={config.lang.rtl ? "row-reverse" : "row"} alignItems={"center"} gap={1}>
            <Typography sx={{ fontSize: "12px", color: colors.tertiary[1], fontWeight: 600 }}>{t("translate.you_have_sold")}</Typography>
            <Typography sx={{
                fontSize: "12px",
                color: "white",
                fontWeight: 600
            }}>{config.currency.symbol}{initFloat(props.amount)}    </Typography>
        </Stack>
    )
}

 

export const WinToast = (props: { amount: number }) => {
    const config = useSelector(getConfig).config;
    const t = useTranslation();

    return (
        <Stack direction={config.lang.rtl ? "row-reverse" : "row"} alignItems={"center"} gap={.5}>
            <Typography
                sx={{ fontSize: "12px", color: "#005415 !important", fontWeight: 600 }}>{t("translate.congrats_you_won")} </Typography>
            <Typography sx={{
                fontSize: "12px",
                fontWeight: 600,
                color: "#005415"
            }}>{config.currency.symbol}{initFloat(props.amount)}    </Typography>
        </Stack>
    )
}

 
export const RejectedToast = (props: { amount: number, reason?: string }) => {
    const t = useTranslation();

    return (
        <Stack direction={"row"} alignItems={"center"} gap={1}>
            <Typography
                sx={{ fontSize: "12px", color: "white", fontWeight: 600 }}> {t("translate.your_bet_rejected")}  {props.reason && `, ${props.reason}`}</Typography>
        </Stack>
    )
}