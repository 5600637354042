import { Stack, useMediaQuery } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import store, { getToast, setToast } from "src/store/store";
import { colors, theme } from "src/utils/theme";
import Chart from "./components/Chart";
import Footer from "./components/Footer";
import Header from "./components/Header";
import {
  BuyToast,
  RejectedToast,
  SellToast,
  WinToast,
} from "./components/ToastComponent/ToastComponent";

function GameBody() {
  // const game = store.getState().game.game;
  const stackRef = useRef<HTMLElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();
  const toast = useSelector(getToast);
  //-- color-change [2023-06-23]
  // const bgcolor = theme.palette.mode === "dark" ? colors.gray[2] : colors.gray[12];
  const bgcolor = theme.palette.mode === "dark" ? "#1B1B33" : colors.gray[12];

  // const [stackDimensions, setStackDimensions] = useState({
  //   width: 0,
  //   height: 0,
  // });

  // useEffect(() => {
  //   if (stackRef.current) {
  //     setStackDimensions({
  //       width: stackRef.current.offsetWidth,
  //       height: stackRef.current.offsetHeight,
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, []);

  const toastTrigger = (type: "buy" | "sell" | "win" | "lose" | "rejected") => {
    switch (type) {
      case "buy":
        enqueueSnackbar(<BuyToast amount={toast.amount} />, {
          autoHideDuration: 2000,
          className: "buyToast",
          preventDuplicate: true,
          onClose: (event, reason, key) => {
            store.dispatch(setToast({ toastType: "", toastMessage: "" }));
          },
        });
        break;
      case "sell":
        enqueueSnackbar(<SellToast amount={toast.amount} />, {
          autoHideDuration: 2000,
          className: "sellToast",
          preventDuplicate: true,
          onClose: (event, reason, key) => {
            store.dispatch(setToast({ toastType: "", toastMessage: "" }));
          },
        });
        break;
      case "win":
        enqueueSnackbar(<WinToast amount={toast.amount} />, {
          autoHideDuration: 2000,
          className: "winToast",
          preventDuplicate: true,
          onClose: (event, reason, key) => {
            store.dispatch(setToast({ toastType: "", toastMessage: "" }));
          },
        });
        break;
      case "rejected":
        enqueueSnackbar(<RejectedToast amount={toast.amount} />, {
          autoHideDuration: 2000,
          className: "rejectedToast",
          preventDuplicate: true,
          onClose: (event, reason, key) => {
            store.dispatch(setToast({ toastType: "", toastMessage: "" }));
          },
        });
        break;
    }
  };

  useEffect(() => {
    if (toast.toastMessage) {
      switch (toast.toastType) {
        case "buy":
          toastTrigger("buy");
          break;
        case "sell":
          toastTrigger("sell");
          break;
        case "win":
          toastTrigger("win");
          break;
        case "lose":
          toastTrigger("lose");
          break;
        case "rejected":
          toastTrigger("rejected");
          break;
      }
    }
    // eslint-disable-next-line
  }, [toast]);

  return (
    <Stack
      gap={2}
      sx={{
        // px: [0, 0, 0],
        justifyContent: "center",
        position: "relative",
        opacity: 1,
        height: "100%",
      }}
      className="nonSelect"
    >
      <Stack
        sx={{
          // minHeight: ["55vh", "50vh", "45vh"],
          // maxHeight: ["60vh", "55vh", "50vh"],
          height: "100%",
          alignItems: ["center", "", ""],
        }}
      >
        <Stack
          sx={{
            bgcolor: isMobile
              ? "linear-gradient(180deg, #3C3553 0%, #1E1B2A 90.75%)"
              : bgcolor,
            // background: "#1D1929",
            // p: 1,
            borderRadius: "8px",
            border: ["","1px solid #2A243A"],
            height: "100%",
            width: ["96%", "100%", "100%"],
          }}
        >
          <Header />
          <Chart />
          {isMobile ? null : <Footer />}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default GameBody;
