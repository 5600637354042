import {
  FAKE_PLAYERS_MAX,
  FAKE_BUY_MIN,
  FAKE_BUY_MAX,
  FAKE_SELL_MIN,
  FAKE_SELL_MAX,
  FAKE_PLAYERS_INTERVAL_ADD,
  FAKE_PLAYERS_INTERVAL_BUY,
  FAKE_PLAYERS_INTERVAL_SELL,
  FAKE_PLAYERS_INTERVAL_REMOVE,
  FAKE_PRICE_INTERVAL,
} from "src/data/constants";
import { globalVars } from "src/data/globals";
import { buyResultHandler } from "src/handlers/buyResultHandler";
import { sellResultHandler } from "src/handlers/sellResultHandler";
import {
  IPlayerBuyResultState,
  IPlayerSellResultState,
} from "src/store/@types";
import store, { setActivePlayers } from "src/store/store";
import { faker } from "@faker-js/faker";

let fakeActivePlayers: any = [];

export const runDemo = () => {
  if (!globalVars.isOffline) return;

  const updatePlayers = () => {
    //-- sort
    const winners = fakeActivePlayers.filter((p: any) => p.status === "won");
    const active = fakeActivePlayers
      .filter((p: any) => p.betAmount > 0 && p.status === "")
      .sort(
        (a: any, b: any) => parseFloat(b.betAmount) - parseFloat(a.betAmount)
      );
    const open = fakeActivePlayers.filter(
      (p: any) => p.betAmount <= 0 && p.status === ""
    );
    const losers = fakeActivePlayers.filter((p: any) => p.status === "lost");

    let newActivePlayers: any = [];
    if (globalVars.player.betAmount > 0) {
      const currentPlayer = {
        ...globalVars.player,
        // playerHistory: globalVars.playerHistory,
      };
      newActivePlayers = [currentPlayer];
    }
    newActivePlayers = [
      ...newActivePlayers,
      ...winners,
      ...active,
      ...open,
      ...losers,
    ];

    store.dispatch(setActivePlayers(newActivePlayers));
  };
  const addPlayer = () => {
    if (fakeActivePlayers.length >= FAKE_PLAYERS_MAX) return;

    const nickname = faker.person.firstName();
    const player = {
      nickname,
      betAmount: getRandomInt(FAKE_BUY_MIN, FAKE_BUY_MAX),
      winAmount: 0,
      price: 0,
      status: "",
    };

    fakeActivePlayers = [...fakeActivePlayers, player];
    updatePlayers();
  };

  const removePlayersWithStatus = () => {
    if (globalVars.player.status !== "") {
      globalVars.player = {
        ...globalVars.player,
        betAmount: 0,
        winAmount: 0,
        buyPrice: 0,
        status: "",
      };
    }
    fakeActivePlayers = fakeActivePlayers.filter((p: any) => p.status === "");
    updatePlayers();
  };

  const updatePlayerBuy = () => {
    if (fakeActivePlayers.length <= 0) return;

    const index: number = getRandomInt(0, fakeActivePlayers.length);
    const player: any = { ...fakeActivePlayers[index] };
    if (player.status !== "") return;

    if (player.betAmount <= 0) {
      player.betAmount = getRandomInt(FAKE_BUY_MIN, FAKE_BUY_MAX);
    }

    fakeActivePlayers[index] = player;
    updatePlayers();
  };

  const updatePlayerSell = () => {
    if (fakeActivePlayers.length <= 0) return;
    const players = fakeActivePlayers.filter(
      (p: any) => p.betAmount > 0 && p.winAmount <= 0 && p.status === ""
    );
    const count = getRandomInt(1, players.length);
    // console.log("count::", count, players.length);
    for (let i = 0; i < count; i++) {
      const index: number = fakeActivePlayers.findIndex(
        (p: any) => p.nickname === players[i].nickname
      );
      const player: any = { ...fakeActivePlayers[index] };
      player.winAmount = getRandomInt(FAKE_SELL_MIN, FAKE_SELL_MAX);
      player.status =
        player.winAmount > 0
          ? player.winAmount > player.betAmount
            ? "won"
            : "lost"
          : "";
      fakeActivePlayers[index] = player;
    }
    updatePlayers();
  };

  // const pAdd =()=>{

  // }
  // pAdd();

  setInterval(() => {
    addPlayer();
  }, FAKE_PLAYERS_INTERVAL_ADD);

  setInterval(() => {
    updatePlayerBuy();
  }, FAKE_PLAYERS_INTERVAL_BUY);
  // }, 500);

  setInterval(() => {
    updatePlayerSell();
  }, FAKE_PLAYERS_INTERVAL_SELL);

  setInterval(() => {
    removePlayersWithStatus();
  }, FAKE_PLAYERS_INTERVAL_REMOVE);

  setInterval(() => {
    globalVars.backendDataPrice = generateY();
  }, 100);

  // requestAnimationFrame(() =>
  //   setInterval(() => {
  //     globalVars.backendDataPrice = generateY();
  //   }, 100)
  // );

  // animate();
  // let requestId: number;
  // requestAnimationFrame(function run() {
  //   setTimeout(function () {
  //     globalVars.backendDataPrice = generateY();
  //     clearTimeout(requestId);
  //     requestId = requestAnimationFrame(run);
  //   }, FAKE_PRICE_INTERVAL);
  // });
};

let requestId: number;
function animate() {
  globalVars.backendDataPrice = generateY();
  // doing some animation stuff
  // get the requestId as part of calling animate()
  clearTimeout(requestId);
  setTimeout(function () {
    requestId = requestAnimationFrame(animate);
  }, FAKE_PRICE_INTERVAL);
}
function stopAnimation(e: any) {
  // use the requestId to cancel the requestAnimationFrame call
  cancelAnimationFrame(requestId);
}

//--- temporary - for demo only 2023-06-14
let yValue: number = 0;
const generateY = () => {
  // yValue+=10 ; return yValue
  // return 1000-200;
  // return 100-250;
  const h = 30000000000; //300
  let newY = yValue + (Math.random() - 0.525) * 100;
  // newY = Math.max(1, Math.min(newY, h + 400));
  newY = Math.max(-40000000000, Math.min(newY, h + 40000000000)); //400
  yValue = newY;
  return newY;
};

export const buyRequestHandlerDemo = (betAmount: number) => {
  const buyData: IPlayerBuyResultState = {
    nickname: globalVars.player.nickname,
    success: true,
    price: globalVars.backendDataPrice,
    betAmount: betAmount,
    buyAmount: betAmount,
  };
  buyResultHandler(buyData);
};

export const sellRequestHandlerDemo = (betAmount: number) => {
  const sellData: IPlayerSellResultState = {
    nickname: globalVars.player.nickname,
    success: true,
    price: globalVars.backendDataPrice,
    betAmount: betAmount,
    buyAmount: betAmount,
    winAmount: 0,
  };
  sellResultHandler(sellData);
};

const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
};
