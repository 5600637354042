import {
    TableBody,
    TableCellProps,
    TableContainer,
    Typography,
    useTheme,
} from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell'
import { FC } from 'react'
import { BACKGROUND_IMAGE_PLAYER_ACTIVE_BET } from 'src/data/constants'
import { StyledTableCell, StyledTableRow } from 'src/pages/trading/components/Game/components/AnalyticsTable/@styles'
import { colors } from 'src/utils/theme'


export const PlayingTableContainer = ({ ...props }) => {
    const theme = useTheme() 
    return (
        <TableContainer
            sx={{ 
                background:theme.palette.mode === 'dark' ? colors.gray[3] : colors.gray[11],
                borderRadius: '8px', 
                // minHeight: ['100%','230px'],
                border: "1px solid #5B536F",
                height: '100%',
                // height:'calc(50vh-200px)', 
                backgroundImage: `url("${BACKGROUND_IMAGE_PLAYER_ACTIVE_BET}") `,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
               
            }}
            className={'nonSelect'}
        >
            {props.children}
        </TableContainer>
    )
}
export const PlayingTableHeadRow: FC<TableCellProps> = ({ ...props }) => {
    const theme = useTheme()

    return (
        <StyledTableCell
            {...props}
            sx={{
                px: 1,
                py: 0.2,
                [`&.${tableCellClasses.head}`]: {
                    backgroundColor:
                        theme.palette.mode === 'dark'
                            ? colors.gray[3] + ' !important'
                            : colors.gray[10] + ' !important',
                    minWidth: 'inherit',
                },
            }}
        >
            <Typography
                variant={'caption'}
                fontSize={12}
                color={theme.palette.mode === 'dark' ? '#837C9C' : colors.gray[7]}
            >
                {props.children}
            </Typography>
        </StyledTableCell>
    )
}

export const PlayingTableBody = ({ ...props }) => {

    return (
        <TableBody 
            sx={{
                background: 'rgba(74, 71, 84, 0.1)',
                '.MuiTableCell-body': {
                    padding: '0 16px',
                    height: '24px',
                },
            }}
        >
            {props.children}
        </TableBody>
    )
}

export const PlayingTableRow = ({ ...props }) => {
    return (
        <StyledTableRow
            {...props}
            // key={index}

            className={''}
            sx={{
                '&.status-won': {
                    // bgcolor: '#BFFFCA',
                    '.nicknameBet': {
                        color: '#0FC943 !important',
                    },
                    '.betAmount': {
                        color: '#0FC943 !important',
                        fontWeight: 700,
                    },
                    '.betWin': {
                        color: '#0FC943 !important',
                        fontWeight: 700,
                    },
                    '.betOdds': {
                        color: '#0FC943 !important',
                        fontWeight: 700,
                    },
                },
                '&.status-lost': {
                    '.nicknameBet': {
                        color: '#FF6768 !important',
                    },
                    '.betAmount': {
                        color: '#FF6768 !important',
                        fontWeight: 700,
                    },
                    '.betWin': {
                        color: '#FF6768 !important',
                        fontWeight: 700,
                    },
                    '.betOdds': {
                        color: '#FF6768 !important',
                        fontWeight: 700,
                    },
                },
            }}
        >
            {props.children}
        </StyledTableRow>
    )
}

export const PlayingTableCell: FC<TableCellProps> = ({ ...props }) => {
    const theme = useTheme()

    return (
        <StyledTableCell
            {...props}
            component="th"
            scope="row"
            sx={{
                borderBottom: 0,
                color: '#FB8111 !important',
                fontWeight: '600 !important',
                textAlign: 'center',
            }}
        >
            <Typography
                className={'nickname'}
                variant={'caption'}
                color={theme.palette.mode === 'dark' ? '#FB8111' : '#FB8111'}
            >
                {props.children}
            </Typography>
        </StyledTableCell>
    )
}
