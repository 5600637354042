import { colors, theme } from "src/utils/theme";

export const PLAYER_INITIAL_BALANCE = 1000;
export const PLAYER_INITIAL_BET_AMOUNT = 100;

export const GRID_SIZE = 110;
export const GRID_LINE_COLOR = 0x3c3553;
export const GRID_LINE_THICKNESS = 1
export const GRID_SIZE_LABEL_DISTANCE = GRID_SIZE / 2;

export const GRAPH_SPEED = 10; //--  15;
export const GRAPH_INTERVAL = 100; //50; //-- 250;
export const GRAPH_BOX_HEIGHT = 50;  //-- price value equivalent per graph step
export const GRAPH_CIRCLE_MARKER_SIZE = 10; 
export const GRAPH_CIRCLE_MARKER_COLOR =  theme.palette.mode === "dark" ? colors.gray[12] : colors.gray[2];
export const GRAPH_HORIZONTAL_LINE_THICKNESS = 1; 
export const GRAPH_BACKGROUND = "./assets/trading/bg.png"; 
export const GRAPH_BACKGROUND_MOBILE = "./assets/trading/bg1.png"; 
export const GRAPH_BACKGROUND_WIDTH_MULTIPLIER =3.3
// export const BACKGROUND_IMAGE = "https://cdn.imoon.com/crashdemo/crashroyale/bloodburst/gamepanelbg.jpg"; 
export const BACKGROUND_IMAGE = "./assets/trading/gamepanelbg.jpg"; 
export const BACKGROUND_IMAGE_PLAYER_ACTIVE_BET = "./assets/trading/playeractivebetbg.jpg"; 


export const MAX_PLAYER_HISTORY = 30; 

export const FAKE_BUY_MIN = 100; 
export const FAKE_SELL_MIN = 100; 
export const FAKE_BUY_MAX = 2000; 
export const FAKE_SELL_MAX = 2000; 
export const FAKE_PLAYERS_MAX = 15; 

export const FAKE_PRICE_INTERVAL =100;// 60; 
export const FAKE_PLAYERS_INTERVAL_ADD = 1000; 
export const FAKE_PLAYERS_INTERVAL_BUY = 600; 
export const FAKE_PLAYERS_INTERVAL_SELL = 2000;  
export const FAKE_PLAYERS_INTERVAL_REMOVE = 3000; 

export const GAME_BACKGROUND_SPEED = 1;//5; 

 