import { Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-multi-lang";
import { IPlayerHistory } from "src/@types";
import HistoryCard from "src/pages/trading/components/Game/components/BetBody/components/History/components/HistoryCard";
import { colors } from "src/utils/theme";
import moment from 'moment'

const HistoryTab = () => {
  const theme = useTheme();
  const textColor = theme.palette.mode === "dark" ? "#fff" : colors.gray[6];
  const t = useTranslation();
  const [playerHistory, setPlayerHistory] = useState<IPlayerHistory[]>([
    {dateTime: moment(Date.now()).format("MMM DD, YYYY"), betAmount: 100, winAmount: 151.1799411318917, status: 'Win', profit: 51.179941131891695, profitPercentage:51.179941131891695},
    {dateTime: moment(Date.now()).format("MMM DD, YYYY"), betAmount: 100, winAmount: 51.570665511462906, status: 'Lose', profit: -48.429334488537094, profitPercentage:-48.429334488537094},
    // {dateTime: moment(Date.now()).format("MMM DD, YYYY"), betAmount: 100, winAmount: 108.07299257962309, status: 'Win', profit: 8.07299257962309, profitPercentage:8.07299257962309},
    // {dateTime: moment(Date.now()).format("MMM DD, YYYY"), betAmount: 100, winAmount: 109.43687492997901, status: 'Win', profit: 9.436874929979012, profitPercentage:9.436874929979012},
    // {dateTime: moment(Date.now()).format("MMM DD, YYYY"), betAmount: 100, winAmount: 113.74616781066894, status: 'Win', profit: 13.746167810668936, profitPercentage:13.746167810668936},
  ]);
 

  return (
    <Stack gap={2}>
      <Typography
        variant="subHeadline"
        color={theme.palette.mode === "dark" ? colors.gray[11] : colors.gray[6]}
      >
        {t("info.gt_history")}
      </Typography>
      <Typography color={textColor} variant={"body"}>
        {t("info.gt_history_p1")}
      </Typography>
      {playerHistory
        ?.filter((p: IPlayerHistory) => p.betAmount > 0)
        ?.map((pHistory: IPlayerHistory, index: number) => {
          return (
            <Stack gap={"12px"} mb={1} key={`history-${index}`}>
              <HistoryCard playerHistory={pHistory} />
            </Stack>
          );
        })}

      {/* <img
        //   src={`${process.env.REACT_APP_CDNSERVER}/${theme.palette.mode}/gameTabs_image2.jpg`}
        src="/assets/game-info/gameTabs_image2.png?1"
          alt="Game Tabs"
        /> */}
    </Stack>
  );
};

export default HistoryTab;
