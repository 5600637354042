import { TableHead, TableRow } from "@mui/material";
import React from "react";
import { t } from "react-multi-lang";
import { theme } from "src/utils/theme";
import { PlayingTableHeadRow } from "./@style";

const PlayersTableHeader = () => {
  return (
    <TableHead>
      <TableRow
        sx={{
          borderBottom:
            theme.palette.mode === "dark" ? "1px solid #3C3553" : "",
        }}
      >
        <PlayingTableHeadRow
          style={{
            borderRight: `1px solid ${
              theme.palette.mode === "dark" ? "#3C3553" : "#EDEDF1"
            }`,
            width: "25%",
            textAlign: "center",
            borderRadius: "8px 0px 0px 0px",
          }}
        >
          {t("translate.name")}
        </PlayingTableHeadRow>

        <PlayingTableHeadRow
          style={{
            width: "25%",
            borderRight: `1px solid ${
              theme.palette.mode === "dark" ? "#3C3553" : "#EDEDF1"
            }`,

            textAlign: "center",
          }}
        >
          {t("translate.amount")}
        </PlayingTableHeadRow>
        <PlayingTableHeadRow
          style={{
            width: "25%",
            borderRight: `1px solid ${
              theme.palette.mode === "dark" ? "#3C3553" : "#EDEDF1"
            }`,
            textAlign: "center",
          }}
        >
          {t("translate.sell")}
        </PlayingTableHeadRow>
        <PlayingTableHeadRow
          style={{
            width: "25%",
            //   borderRight: `1px solid ${theme.palette.mode === "dark" ? '#3C3553' : '#EDEDF1'}`,
            borderRadius: "0px 8px 0px 0px",
            textAlign: "center",
          }}
        >
          {t("translate.profit")}
        </PlayingTableHeadRow>
      </TableRow>
    </TableHead>
  );
};

export default  PlayersTableHeader;
