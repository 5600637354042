import { createSlice } from '@reduxjs/toolkit'

export const modalSlice = createSlice({
   name: 'modal',
   initialState: { modal: { type: null, data: {} } },
   reducers: {
      setModal(state, Action) {
         state.modal = Action.payload
      },
   },
})
