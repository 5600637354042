import axios from "axios";
import { Events } from "src/@types";
import store from "src/store/store";
import { webSocketSender } from "src/websocket/websocket";
export const sellRequestHandler = async (betAmount: string | number | {}) => {
  try {
    const nickname = store.getState().player.player.nickname;
    webSocketSender({ betAmount, nickname }, "sell");
  
    const req: {} = {
      hubName: process.env.REACT_APP_TRADING_HUB_NAME,
      groupName: process.env.REACT_APP_PUB_SUB_GROUP,
      message: {
        event: Events.SELL_REQ,
        data: { betAmount, nickname },
      },
    };
    await axios.post("http://localhost:5000/trading/sellRequest", req);
  } catch (error) {
    
  }
};
