import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-multi-lang";
import { Playing } from "./Playing";
import { colors } from "src/utils/theme";
import { ArrowLeft } from "../SvgComponent";
import { InformationModalProps } from "../../types/propsType";
import ChatTab from "./ChatTab";
import HistoryTab from "./HistoryTab";
 
export const GameTabsModalComponent: FC<InformationModalProps> = ({
  activePage,
  setActivePage,
}) => {
  const theme = useTheme();
  const t = useTranslation();

  return (
    <Stack gap={2}>
      <Stack
        direction={"row"}
        gap={1}
        alignItems={"center"}
        mb={1}
        sx={{
          position: "sticky",
          top: 0,
          bgcolor: theme.palette.mode === "dark" ? colors.gray[2] : "#fff",
          pt: 2,
          pb: 1,
          zIndex: 3,
        }}
      >
        <IconButton onClick={() => setActivePage("info")}>
          <ArrowLeft />
        </IconButton>
        <Typography
          variant={"headline"}
          color={theme.palette.mode === "light" ? colors.gray[5] : "#fff"}
        >
          {t("info.gt_title")}
        </Typography>
      </Stack>
      <Playing />
      <ChatTab />
      <HistoryTab />
    </Stack>
  );
};
