import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-multi-lang';
import { colors } from 'src/utils/theme';

const BuySellShares = () => {
    const theme = useTheme();
    const textColor = theme.palette.mode === "dark" ? "#fff" : colors.gray[6];
    const t = useTranslation();
  
    const multiplierRulesList = [
      `${t("info.gb_buysell_li1")}`,
      `${t("info.gb_buysell_li2")}`,
      `${t("info.gb_buysell_li3")}`,
      `${t("info.gb_buysell_li4")}`,
      `${t("info.gb_buysell_li5")}`,
    ];
    return (
      <Box>
        <Stack direction={"row"} alignItems={"center"} gap={1} mt="40px">
          <Typography
            sx={{
              background: colors.gray[7],
              // height: 17,
              // width: 17,
              textAlign: "center",
              borderRadius: "50%",
              color: colors.gray[1],
              fontSize: "13px",
              px: "6px",
            }}
          >
            1
          </Typography>
          <Typography color={textColor}>{t("info.gb_buysell")}</Typography>
        </Stack>
        <ul style={{ padding: "0 25px" }}>
          {multiplierRulesList.map((li) => (
            <li style={{ color: "#fff" }} key={li}>
              <Typography variant={"body"} color={textColor}>
                {li}
              </Typography>
            </li>
          ))}
        </ul>
      </Box>
    );
  };
  
export default BuySellShares