import store, {
  setPlayer,
  setPlayerBuyResult,
  setToast,
} from "src/store/store";
import { IPlayerBuyResultState } from "src/store/@types";
import { globalVars } from "src/data/globals";

export const buyResultHandler = (buyResult: IPlayerBuyResultState) => {
  if (buyResult.nickname !== globalVars.player.nickname) return; 

  const playerBalance: number = Number(store.getState().player.player.balance);

  store.dispatch(setPlayerBuyResult(buyResult));
  if (!buyResult.success) {
    store.dispatch(
      setToast({
        toastType: "rejected",
        toastMessage: "Oh! Your Bet Has Been Rejected!",
        amount: Number(buyResult.betAmount).toFixed(2),
      })
    );
    store.dispatch(setPlayerBuyResult({ success: false })); //-- Enable (success:false) / disable (success:true) Buy button
  } else {
    globalVars.player.betAmount = buyResult.betAmount;
    globalVars.player.buyAmount = buyResult.betAmount;
    globalVars.player.buyPrice = buyResult.price;
    globalVars.player.winAmount = 0;
    globalVars.player.status = "";

    let balance = playerBalance - buyResult.betAmount;
    if (balance < 0) {
      balance = 0;
    }
    store.dispatch(setPlayer({ balance, status: "" }));

    store.dispatch(
      setToast({
        toastType: "buy",
        toastMessage: "You have bought",
        amount: Number(buyResult.betAmount).toFixed(2),
      })
    );
  }
};
