import { eventSender } from "src/listeners/messageListener";
import { IPlayerState } from "src/store/@types";
import store, { setPlayer } from "src/store/store";

export const BuyStock = (betAmount: number) => {
  eventSender("buyRequest", { betAmount });
  // if (globalVars.isOffline) {
  //   buyRequestHandlerDemo(betAmount);
  // } else {
  //   buyRequestHandler(betAmount);
  // }
};

export const SellStock = (betAmount: number) => {
  eventSender("sellRequest", { betAmount });
  // if (globalVars.isOffline) {
  //   sellRequestHandlerDemo(betAmount);
  // } else {
  //   sellRequestHandler(betAmount);
  // }
};

export const SetBetMax = () => {
  const game = store.getState().game.game;
  let betAmount: number = Number(
    store.getState().player.player.balance?.toFixed(0)
  );
  if (betAmount > Number(game.maxBetAmount)) {
    betAmount = Number(game.maxBetAmount);
  }
  // Update bet amount equivalent to current balance
  store.dispatch(setPlayer({ betAmount }));
};

export const SetBetHalf = () => {
  let betAmount: number = Number(
    (Number(store.getState().player.player.betAmount) / 2).toFixed(2)
  );
  // Half bet amount
  if (parseInt(betAmount.toFixed(0)) <= 1) {
    betAmount = 1;
  }
  store.dispatch(setPlayer({ betAmount }));
};

export const SetBetDouble = () => {
  const player: IPlayerState = store.getState().player.player;
  const balance: number = Number(player.balance);
  const game = store.getState().game.game;
  let betAmount: number = Number((Number(player.betAmount) * 2).toFixed(2));
  // Double bet amount with a maximum value of current balance
  if (betAmount > balance) {
    betAmount = Math.round(balance);
  }
  if (betAmount > Number(game.maxBetAmount)) {
    betAmount = Number(game.maxBetAmount);
  }
  store.dispatch(setPlayer({ betAmount }));
};

export const UpdateBetAmount = (betAmount: string) => {
  const game = store.getState().game.game;
  const balance: number = Number(
    store.getState().player.player.balance?.toFixed(0)
  );

  console.log("betAmount::", betAmount, betAmount?.split(".").length);
  const arrBetAmount: string[] = betAmount?.split(".");
  if (betAmount.startsWith("0")) return;
  if (
    arrBetAmount.length > 2 ||
    (arrBetAmount.length > 1 && arrBetAmount[1].length > 2)
  )
    return;

  // if (Number(betAmount) === balance) return;

  // Update bet amount and must not exceed current balance and maxBetAmount
  if (Number(betAmount) > Number(game.maxBetAmount)) {
    store.dispatch(setPlayer({ betAmount: Number(game.maxBetAmount) }));
    return;
  }

  if (Number(betAmount) <= balance) {
    store.dispatch(setPlayer({ betAmount }));
  } else {
    store.dispatch(setPlayer({ betAmount: balance }));
  }
};
